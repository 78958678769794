import React from "react";
import { AuthContextProvider } from "./components/AuthContext";
import IRISThemeProvider from "./utils/theme/theme";
import AppRoutes from "./routes/AppRoutes";
import AlertContextProvider from "./irisUI/alert/context/AlertContextProvider";
import AlertModal from "./irisUI/alert/view/AlertModal";
import ConfirmContextProvider from "./irisUI/confirm/context/ConfirmContextProvider";
import ConfirmModal from "./irisUI/confirm/view/ConfirmModal";
import { MDSSProvider } from "context/MDSSContext";

function App() {
  return (
    <IRISThemeProvider>
      {/* global alert context and ui */}
      <MDSSProvider>
        <AlertContextProvider>
          <AlertModal />
          <ConfirmContextProvider>
            <ConfirmModal />
            <AuthContextProvider>
              <AppRoutes />
            </AuthContextProvider>
          </ConfirmContextProvider>
        </AlertContextProvider>
      </MDSSProvider>
    </IRISThemeProvider>
  );
}

export default App;
