import { useState, useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField } from "@material-ui/core";
import useInput from "customHooks/userInput";
import { AWS_PASSWORD_MIN_LENGTH, AWS_PASSWORD_RULES } from "aws/utils";
import Auth from "@aws-amplify/auth";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageDiv: {
    width: 500,
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    verticalAlign: "middle",
    border: "10px solid #efefef",
    backgroundColor: "#fff",
    transition: ".3s ease",
    boxShadow: `inset 1px 1px 3px rgba(0, 0, 0, .3),
		  1px 1px 2px rgba(0, 0, 0, .2),
		  40px 35px 70px -10px rgba(0, 0, 0, .5),
		  -20px -20px 100px -10px rgba(255, 255, 255, .8)`,

    "& > *": {
      margin: "10px 0",
    },
  },
}));

const containsSpecialChars = (string) => {
  return /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/\\]/.test(string);
};

const containsOneLowerCase = (string) => {
  return /[A-Z]/.test(string);
};
const containsOneUpperCase = (string) => {
  return /[a-z]/.test(string);
};
const containsOneNumericChar = (string) => {
  return /[0-9]/.test(string);
};
export const ResetPasswordModal = (props) => {
  const {
    open: showModal,
    onClose: handleModalClose,
    tempCognitoUser,
    updateRestPasswordInfo,
  } = props;

  const [password0, changePassword0, resetPassword0] = useInput("");
  const [password1, changePassword1, resetPassword1] = useInput("");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const handleClose = () => {
    if (typeof handleModalClose === "function") {
      handleModalClose();
    }
  };

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const getHelperText = () => {
    const password = password0.trim();
    const isTooShort = password.length < 8;
    const hasOneNumericChar = containsOneNumericChar(password);
    const hasOneUppercaseChar = containsOneUpperCase(password);
    const hasOneLowercaseChar = containsOneLowerCase(password);
    const hasOneSpecialChar = containsSpecialChars(password);

    return (
      <div>
        <span
          style={{
            display: "block",
            fontSize: 14,
            color: isTooShort ? "red" : "",
          }}
        >
          {AWS_PASSWORD_RULES.AT_LEAST_EIGHT_CHARACTERS}
        </span>
        <span
          style={{
            display: "block",
            fontSize: 14,
            color: !hasOneNumericChar ? "red" : "",
          }}
        >
          {AWS_PASSWORD_RULES.MUST_HAVE_NUMERIC_CHARACTER}
        </span>
        <span
          style={{
            display: "block",
            fontSize: 14,
            color: !hasOneUppercaseChar ? "red" : "",
          }}
        >
          {AWS_PASSWORD_RULES.MUST_HAVE_LOWERCASE_CHARACTER}
        </span>
        <span
          style={{
            display: "block",
            fontSize: 14,
            color: !hasOneLowercaseChar ? "red" : "",
          }}
        >
          {AWS_PASSWORD_RULES.MUST_HAVE_UPPERCASE_CHARACTER}
        </span>
        <span
          style={{
            display: "block",
            fontSize: 14,
            color: !hasOneSpecialChar ? "red" : "",
          }}
        >
          {AWS_PASSWORD_RULES.MUST_HAVE_SPECIAL_SYMBOL}
        </span>
      </div>
    );
  };

  const isPasswordValid = () => {
    const password = password0.trim();
    const isTooShort = password.length < 8;
    const hasOneNumericChar = containsOneNumericChar(password);
    const hasOneUppercaseChar = containsOneUpperCase(password);
    const hasOneLowercaseChar = containsOneLowerCase(password);
    const hasOneSpecialChar = containsSpecialChars(password);

    const result =
      !isTooShort &&
      hasOneNumericChar &&
      hasOneUppercaseChar &&
      hasOneLowercaseChar &&
      hasOneSpecialChar;

    return (
      !isTooShort &&
      hasOneUppercaseChar &&
      hasOneLowercaseChar &&
      hasOneSpecialChar
    );
  };

  const shouldDisabled = () => {
    const pass0 = password0.trim();
    const pass1 = password1.trim();

    const samelength =
      pass0.length >= AWS_PASSWORD_MIN_LENGTH && pass0 === pass1;
    const hasNumericChar = containsOneNumericChar(pass0);
    const hasSpecialChar = containsSpecialChars(pass0);
    const hasUppercaseChar = containsOneUpperCase(pass0);
    const hasLowercaseChar = containsOneLowerCase(pass0);

    return (
      !samelength ||
      !hasNumericChar ||
      !hasSpecialChar ||
      !hasUppercaseChar ||
      !hasLowercaseChar
    );
  };

  const handleRestPassword = async () => {
    try {
      setIsResetting(false);
      const user = await Auth.completeNewPassword(
        tempCognitoUser,
        password0.trim()
      );
      updateRestPasswordInfo({
        message: "Password is reset",
        severity: "success",
        open: true,
        position: "top-center",
      });
      resetPassword0();
      resetPassword1();
      props.onClose();

      setIsResetting(true);
    } catch (error) {
      console.log("error in reseting password", error);
      setIsResetting(false);
      updateRestPasswordInfo({
        message: "Password is NOT reset",
        severity: "error",
        open: true,
        position: "top-center",
      });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.imageDiv}>
            <h3>Reset password</h3>
            <TextField
              placeholder="New Password"
              type="password"
              value={password0}
              onChange={changePassword0}
              helperText={getHelperText()}
            />
            <TextField
              placeholder="Confirm New Password"
              type="password"
              disabled={!isPasswordValid()}
              value={password1}
              onChange={changePassword1}
              helperText={
                password1.trim().length > 0 &&
                password1.trim() === password0.trim()
                  ? "Matched"
                  : password1.trim().length === 0
                  ? ""
                  : "Mismatched"
              }
            />
            <Button
              variant="contained"
              color="primary"
              disabled={shouldDisabled() || isResetting}
              onClick={handleRestPassword}
              endIcon={
                isResetting && <i className="fa fa-circle-o-notch fa-spin" />
              }
            >
              Reset
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
