import { getMockedWeatherData } from "demoAPIData/weatherData";

// Open Weather API
const OPEN_WEATHER_URL = new URL("https://api.openweathermap.org/data/2.5/");
const OPEN_WEATHER_CURRENT_URL = `${OPEN_WEATHER_URL}weather`;
export const OPEN_WEATHER_CITY_LIST = {
  Toronto: { name: "Demo city 0", province: "Ontario", code: 1 },
  London: { name: "Demo city 1", province: "Ontario", code: 2 },
  Oakville: { name: "Demo city 2", province: "Ontario", code: 3 },
  Hamilton: { name: "Demo city 3", province: "Ontario", code: 4 },
  Burlington: { name: "Demo city 4", province: "Ontario", code: 5 },
  Sagamihara: {
    name: "Demo city 5",
    province: "Kanagawa Prefecture",
    code: 1853295,
  },
  Vaughan: { name: "Demo city 6", province: "Ontario", code: 6 },
};
// city id (code)
// http://web.archive.org/web/20180619015316/http://openweathermap.org/help/city_list.txt
export const OPEN_WEATHER_API_KEY = "";

export const getCurrentWeather = (cityId) => {
  return new Promise((resolve, reject) => {
    const data = getMockedWeatherData();
    resolve({
      dt: data.dt,
      temp: data.main.temp,
      city: data.name,
      condition: data.weather[0].main,
      icon: data.weather[0].icon,
    });
  });
};
