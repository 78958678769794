// import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import HomePageLayout from "../layout/HomePageLayout";
import { PageBody } from "../layout/PageBody";
// const useStyles = makeStyles((theme) => ({}));

export default function AdminConsole(props) {
  // const [openDrawer, setOpenDrawer] = useState(true);

  // const toggleDrawer = () => {
  //   setOpenDrawer((prev) => !prev);
  // };

  return (
    <div>
      <HomePageLayout {...props}>
        <PageBody />
      </HomePageLayout>
    </div>
  );
}
