import moment from "moment";

const MDSS = {
  data: {
    listMDSSES: {
      listMDSSES: [
        {
          air_temperature: "-0.2",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-3.9",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "76",
          road_alert: "OK",
          road_temperature: "2.55",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 11:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "160",
          wind_speed: "12 km/h",
        },
        {
          air_temperature: "1.4",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-2.6",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "75",
          road_alert: "OK",
          road_temperature: "5.5",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 12:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "164",
          wind_speed: "13 km/h",
        },
        {
          air_temperature: "2.5",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-1.4",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "75",
          road_alert: "OK",
          road_temperature: "7.63",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 13:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "170",
          wind_speed: "14 km/h",
        },
        {
          air_temperature: "3.3",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-0.3",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "77",
          road_alert: "OK",
          road_temperature: "8.85",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 14:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "172",
          wind_speed: "16 km/h",
        },
        {
          air_temperature: "4",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "0.7",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.02",
          rh: "79",
          road_alert: "OK",
          road_temperature: "9.43",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 15:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "136",
          wind_speed: "16 km/h",
        },
        {
          air_temperature: "4.4",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "1.5",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.03",
          rh: "82",
          road_alert: "OK",
          road_temperature: "9.33",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 16:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "62",
          wind_speed: "16 km/h",
        },
        {
          air_temperature: "4.3",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "2",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.05",
          rh: "85",
          road_alert: "OK",
          road_temperature: "8.42",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 17:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "26",
          wind_speed: "16 km/h",
        },
        {
          air_temperature: "3.8",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "2",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.05",
          rh: "88",
          road_alert: "OK",
          road_temperature: "6.64",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 18:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "34",
          wind_speed: "15 km/h",
        },
        {
          air_temperature: "3",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "1.7",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.07",
          rh: "91",
          road_alert: "OK",
          road_temperature: "4.52",
          route_id: "DONVMG",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 19:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "50",
          wind_speed: "15 km/h",
        },
        {
          air_temperature: "1",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-4",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "69",
          road_alert: "OK",
          road_temperature: "3.52",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 11:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "172",
          wind_speed: "11 km/h",
        },
        {
          air_temperature: "2.5",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-3.1",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "67",
          road_alert: "OK",
          road_temperature: "6.45",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 12:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "174",
          wind_speed: "13 km/h",
        },
        {
          air_temperature: "3.5",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-2.2",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "67",
          road_alert: "OK",
          road_temperature: "8.45",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 13:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "176",
          wind_speed: "15 km/h",
        },
        {
          air_temperature: "4.1",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "-1.2",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0",
          rh: "68",
          road_alert: "OK",
          road_temperature: "9.63",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 14:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "176",
          wind_speed: "16 km/h",
        },
        {
          air_temperature: "4.8",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "0.3",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.05",
          rh: "73",
          road_alert: "OK",
          road_temperature: "10.19",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 15:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "136",
          wind_speed: "16 km/h",
        },
        {
          air_temperature: "5.1",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "1.7",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.1",
          rh: "79",
          road_alert: "OK",
          road_temperature: "10.07",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 16:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "54",
          wind_speed: "15 km/h",
        },
        {
          air_temperature: "5.1",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "2.7",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.15",
          rh: "85",
          road_alert: "OK",
          road_temperature: "9.21",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 17:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "12",
          wind_speed: "14 km/h",
        },
        {
          air_temperature: "4.5",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "3",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.34",
          rh: "90",
          road_alert: "OK",
          road_temperature: "7.23",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 18:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "22",
          wind_speed: "14 km/h",
        },
        {
          air_temperature: "3.6",
          available_chemicals: "0",
          blowing_snow_alert: "OK",
          dewpoint: "2.7",
          freezing_rate: "0",
          mobility_index: "100",
          plow: "False",
          rain_rate: "0.53",
          rh: "94",
          road_alert: "OK",
          road_temperature: "5",
          route_id: "DONVKV",
          snow_on_road: "0",
          snow_rate: "0",
          time: "03/14/2022 19:00:00",
          treatment: "",
          weather_alert: "OK",
          wind_alert: "OK",
          wind_direction: "44",
          wind_speed: "14 km/h",
        },
      ],
      nextToken: null,
    },
  },
};

const DONVMG_ITEMS = [
  {
    air_temperature: "-0.2",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-3.9",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "76",
    road_alert: "OK",
    road_temperature: "2.55",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 11:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "160",
    wind_speed: "12 km/h",
  },
  {
    air_temperature: "1.4",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-2.6",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "75",
    road_alert: "OK",
    road_temperature: "5.5",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 12:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "164",
    wind_speed: "13 km/h",
  },
  {
    air_temperature: "2.5",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-1.4",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "75",
    road_alert: "OK",
    road_temperature: "7.63",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 13:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "170",
    wind_speed: "14 km/h",
  },
  {
    air_temperature: "3.3",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-0.3",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "77",
    road_alert: "OK",
    road_temperature: "8.85",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 14:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "172",
    wind_speed: "16 km/h",
  },
  {
    air_temperature: "4",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "0.7",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.02",
    rh: "79",
    road_alert: "OK",
    road_temperature: "9.43",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 15:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "136",
    wind_speed: "16 km/h",
  },
  {
    air_temperature: "4.4",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "1.5",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.03",
    rh: "82",
    road_alert: "OK",
    road_temperature: "9.33",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 16:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "62",
    wind_speed: "16 km/h",
  },
  {
    air_temperature: "4.3",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "2",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.05",
    rh: "85",
    road_alert: "OK",
    road_temperature: "8.42",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 17:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "26",
    wind_speed: "16 km/h",
  },
  {
    air_temperature: "3.8",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "2",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.05",
    rh: "88",
    road_alert: "OK",
    road_temperature: "6.64",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 18:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "34",
    wind_speed: "15 km/h",
  },
  {
    air_temperature: "3",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "1.7",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.07",
    rh: "91",
    road_alert: "OK",
    road_temperature: "4.52",
    route_id: "DONVMG",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 19:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "50",
    wind_speed: "15 km/h",
  },
];
const DONVKV_ITEMS = [
  {
    air_temperature: "1",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-4",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "69",
    road_alert: "OK",
    road_temperature: "3.52",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 11:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "172",
    wind_speed: "11 km/h",
  },
  {
    air_temperature: "2.5",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-3.1",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "67",
    road_alert: "OK",
    road_temperature: "6.45",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 12:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "174",
    wind_speed: "13 km/h",
  },
  {
    air_temperature: "3.5",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-2.2",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "67",
    road_alert: "OK",
    road_temperature: "8.45",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 13:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "176",
    wind_speed: "15 km/h",
  },
  {
    air_temperature: "4.1",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "-1.2",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0",
    rh: "68",
    road_alert: "OK",
    road_temperature: "9.63",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 14:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "176",
    wind_speed: "16 km/h",
  },
  {
    air_temperature: "4.8",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "0.3",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.05",
    rh: "73",
    road_alert: "OK",
    road_temperature: "10.19",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 15:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "136",
    wind_speed: "16 km/h",
  },
  {
    air_temperature: "5.1",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "1.7",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.1",
    rh: "79",
    road_alert: "OK",
    road_temperature: "10.07",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 16:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "54",
    wind_speed: "15 km/h",
  },
  {
    air_temperature: "5.1",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "2.7",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.15",
    rh: "85",
    road_alert: "OK",
    road_temperature: "9.21",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 17:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "12",
    wind_speed: "14 km/h",
  },
  {
    air_temperature: "4.5",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "3",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.34",
    rh: "90",
    road_alert: "OK",
    road_temperature: "7.23",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 18:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "22",
    wind_speed: "14 km/h",
  },
  {
    air_temperature: "3.6",
    available_chemicals: "0",
    blowing_snow_alert: "OK",
    dewpoint: "2.7",
    freezing_rate: "0",
    mobility_index: "100",
    plow: "False",
    rain_rate: "0.53",
    rh: "94",
    road_alert: "OK",
    road_temperature: "5",
    route_id: "DONVKV",
    snow_on_road: "0",
    snow_rate: "0",
    time: "03/14/2022 19:00:00",
    treatment: "",
    weather_alert: "OK",
    wind_alert: "OK",
    wind_direction: "44",
    wind_speed: "14 km/h",
  },
];

const getMdssItems = (startTime, mdssItems) => {
  const momentObj = moment(startTime);
  const result = mdssItems.map((item, index) => {
    momentObj.add(1, "h");
    const timeString = momentObj.format("MM/DD/YYYY HH:00:00");
    return { ...item, time: timeString };
  });

  return result;
};

export const getMdssRequestResult = (startTime) => {
  const items0 = getMdssItems(startTime, DONVKV_ITEMS);
  const items1 = getMdssItems(startTime, DONVMG_ITEMS);

  const mdssCopy = JSON.parse(JSON.stringify(MDSS));
  mdssCopy.data.listMDSSES.items = [...items0, ...items1];

  return mdssCopy;
};
