import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import { OPEN_WEATHER_CITY_LIST } from "../utils/env/options";
import {
  getCurrentWeather,
  OPEN_WEATHER_CITY_LIST,
} from "../../utils/openWeatherAPI/openWeatherAPI";
// import moment from "moment-timezone";

const HEIGHT = 70;

/**5 minutes */
const REFRESH_WEATHER_INFO_INTERVAL = 5 * 60 * 1000;
const useStyles = makeStyles((theme) => ({
  root: {
    height: HEIGHT,
  },
  subtitle: {
    marginLeft: "5px",
  },
  weatherIcon: { height: HEIGHT, paddingBottom: "10px" },
}));

export default function WeatherView({ cityCode }) {
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const classes = useStyles(visibility);

  useEffect(() => {
    let isSubscribed = true;
    function getWeather() {
      getCurrentWeather(cityCode)
        .then((result) => {
          if (isSubscribed) {
            result["cityInfo"] = Object.values(OPEN_WEATHER_CITY_LIST).find(
              (city) => city.code === cityCode
            );
            console.log(result);
            setWeatherInfo(result);
            setVisibility(true);
            console.log(`weather updated`);
          }
        })
        .catch((error) => {
          // debugger;
          console.log(error);
        });
    }
    let interval = setInterval(() => {
      getWeather();
    }, REFRESH_WEATHER_INFO_INTERVAL);

    getWeather();
    return () => {
      isSubscribed = false;

      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
  }, [cityCode]);

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item>
        <Typography align="right" variant="h6">
          {visibility ? `${Math.round(weatherInfo.temp)}℃` : "--"}
        </Typography>
        <Typography align="right" variant="body1">
          {visibility ? weatherInfo.condition : "unknown"}
        </Typography>
      </Grid>
      <Grid item>
        {visibility ? (
          <img
            src={`https://openweathermap.org/img/wn/${weatherInfo.icon}@2x.png`}
            alt={weatherInfo.condition}
            className={classes.weatherIcon}
          />
        ) : null}
      </Grid>
      <Grid item>
        {/* <Typography variant="body1">
          {visibility
            ? `${weatherInfo.cityInfo.name}, ${weatherInfo.cityInfo.province}`
            : "City, Province"}
        </Typography> */}

        {visibility ? (
          <>
            <Typography variant="body1">{weatherInfo.cityInfo.name}</Typography>
            <Typography variant="body1">
              {weatherInfo.cityInfo.province}
            </Typography>
          </>
        ) : (
          <Typography variant="body1"> City, Province</Typography>
        )}
      </Grid>
    </Grid>
  );
}
