import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../components/Login";
import HomePage from "../components/Homepage";
import NotFound from "../components/NotFound";
import PrivateRoute from "../components/PrivateRoute";
import { PROTECTED_ROUTES } from "./data/protectedRoutes";
import ResetPassword from "components/ResetPassword";

export default function AppRoutes() {
  return (
    <Router>
      <Switch>
        <PrivateRoute
          exact
          path={[
            ...Object.values(PROTECTED_ROUTES).map((value) => value.path),
            "/patrol",
          ]}
          component={HomePage}
        />

        <Route exact path="/login" component={Login} />

        <Route exact path="/login/reset" component={ResetPassword} />

        <Route exact component={NotFound} />
      </Switch>
    </Router>
  );
}
