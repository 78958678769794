import { getMdssRequestResult } from "demoAPIData/mdssResult";
import {
  DEVICE_STATUS_URL,
  IRISGO_BASE_URL,
  REBOOT_DEVICE_URL,
} from "utils/env/urls";

/**
 * @description mark device as in patrol or finish patrol on the server
 *
 * @param {string} token access token
 * @param {object} params param object
 * @param {boolean} inPatrol ture means start patrol, otherwise, false
 * @returns {Promise}
 */
export const togglePatrolRequest = async (token, params, inPatrol) => {
  return new Promise(async (resolve, reject) => {
    resolve({ success: true });
  });
};
/**
 * @description send road condition to server
 *
 * @param {string} accessToken access token
 * @param {string} device_sn device serial number
 * @param {string} roadCondition road condition name
 * @returns {Promise}
 */
export const sendRoadCondition = async (
  accessToken,
  device_sn,
  roadCondition
) => {
  return new Promise(async (resolve, reject) => {
    resolve({ success: true });
  });
};

/**
 * @description send request to reboot a device
 * @param {number} id device id
 * @returns A promise, on resolve, device should be rebooting, if it is on the grid of Hexnode, false otherwise
 */
export const getDeviceStatus = async (id) => {
  return new Promise(async (resolve, reject) => {
    resolve({ active: Math.random() > 0.01 });
  });
};

/**
 *
 * @param {number} deviceId the device's id for rebooting
 * @returns
 */
export const rebootDevice = async (deviceId) => {
  return new Promise(async (resolve, reject) => {
    resolve({ success: true });
  });
  // return { success: true };
};

export const fetchMDSS = async (fromTime, toTime) => {
  return new Promise(async (resolve, reject) => {
    const fetchedData = getMdssRequestResult(fromTime);

    //customize data by adding required properties
    const customizedData = fetchedData.data.listMDSSES.items;
    customizedData.forEach((oneData) => {
      oneData["Air Temperature (°C)"] = oneData["air_temperature"];
      oneData["Road Temperature (°C)"] = oneData["road_temperature"];
      oneData["Snow on Road (cm)"] = oneData["snow_on_road"];
    });

    //add precipitation property to each data object
    customizedData.forEach((oneData) => {
      const sortableKV = [
        ["FREEZING", Number(oneData["freezing_rate"])],
        ["RAIN", Number(oneData["rain_rate"])],
        ["SNOW", Number(oneData["snow_rate"])],
      ];
      sortableKV.sort((a, b) => b[1] - a[1]);
      // oneData["Precipitation Rate"] = sortableKV[0][1].toString();
      if (sortableKV[0][1] === 0) {
        oneData["precipitation"] = {
          precipitation_type: "None",
          rate: sortableKV[0][1].toString(),
        };
        // oneData["precipitation_type"] = "None";
      } else {
        oneData["precipitation"] = {
          precipitation_type: sortableKV[0][0],
          rate: sortableKV[0][1].toString(),
        };
        // oneData["precipitation_type"] = sortableKV[0][0];
      }
      // oneData["Rate"] = sortableKV[0][1].toString();
    });

    //console.log to check if the times are correct
    // console.log("right now", new Date());
    // console.log("fetch from ", fromTime);
    // console.log("fetch to ", toTime);
    // console.log("data output from patrol API ", customizedData);

    resolve(customizedData);
  });
};
