import React, { useState } from "react";
export const AuthContext = React.createContext({
  deviceInfo: { id: -1, device_sn: "", tag: "" },
});

export const AuthContextProvider = ({ children }) => {
  const [contextUsername, setContextUsername] = useState();
  const [contextToken, setContextToken] = useState();

  const [deviceInfo, setDeviceInfo] = useState(null);
  const [labels, setLabels] = useState([]);

  const contextValue = {
    contextUsername, // okay
    contextToken,
    deviceInfo,
    labels,

    setContextUsername,
    setContextToken,
    setDeviceInfo,
    setLabels,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
