import styles from "./TabPanel.module.css";

import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  heading: {
    flexBasis: "100%",
    flexShrink: 0,
    minHeight: 0,
    fontSize: "1.8rem",
    fontWeight: 700,
  },
}));

//transfer text with _ to text with space and Case sensitive
const replaceUnderscore = (str) => {
  const targetStr = str.split("_").map((word) => {
    const splitTemp = word.split("");
    splitTemp[0] = splitTemp[0].toUpperCase();
    return splitTemp.join("");
  });
  return targetStr.join(" ");
};

/**
 *
 * @param {string} timeStr
 */
//to get the accordion title of time
const getTimeTitle = (timeStr) => {
  const curTime = moment();
  const targetTime = moment(timeStr);
  //difference between these 2 time in seconds
  const secDiff = curTime.unix() - targetTime.unix();
  //3 cases when target time and current time is no more than 24 hours different
  if (Math.abs(secDiff) <= 24 * 60 * 60) {
    //current time and target time on the same date
    if (curTime.date() === targetTime.date()) {
      return targetTime.format("HH:mm");
    }
    //target time is on the next day of current time
    else if (secDiff < 0) {
      return `${targetTime.format("HH:mm")} (+1) day(s)`;
    }
    //target time is on the previous day of current time
    else if (secDiff > 0) {
      return `${targetTime.format("HH:mm")} (-1) day(s)`;
    }
  }
  //target time and current time are more than one days away from each other
  //target time is in the future of current time
  else if (secDiff < 0) {
    const daysDiff = Math.round(Math.abs(secDiff) / (24 * 60 * 60));
    return `${targetTime.format("HH:mm")} (+${daysDiff}) day(s)`;
  }
  //target time is in the past of current time
  else if (secDiff > 0) {
    const daysDiff = Math.round(Math.abs(secDiff) / (24 * 60 * 60));
    return `${targetTime.format("HH:mm")} (-${daysDiff}) day(s)`;
  }
};

const TabPanelContent = ({
  timeExpanded,
  handleChangeTime,
  ACCORDION_DETAILS,
}) => {
  const classes = useStyles();

  if (ACCORDION_DETAILS) {
    //accordion summary shows the time relatively
    const weatherTime = ACCORDION_DETAILS["time"];
    //add "(+1)" when the rendered time is on next day
    let renderedTime = getTimeTitle(weatherTime);

    const weatherCondition = ACCORDION_DETAILS["road_alert"].toUpperCase();
    return (
      <div className={styles["one-content"]}>
        <Accordion
          expanded={timeExpanded === renderedTime}
          onChange={handleChangeTime(renderedTime, weatherCondition)}
        >
          <AccordionSummary
            style={
              timeExpanded === renderedTime
                ? { borderBottom: "var(--mdss-grey) 0.4rem solid" }
                : {}
            }
            className={`${styles.weatherDiv} ${
              styles[`weather-forecast-${weatherCondition}`]
            }`}
          >
            <Typography className={classes.heading}>{renderedTime}</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", padding: 0 }}
            className={styles[`weather-forecast-accordion-${weatherCondition}`]}
          >
            {Object.keys(ACCORDION_DETAILS)
              //filter time property because not showing it in content
              .filter((lvl1key) => lvl1key !== "time")
              //arrange the content in alphabetical order
              .sort((a, b) => {
                if (a.toLowerCase() < b.toLowerCase()) {
                  return -1;
                } else if (a.toLowerCase() > b.toLowerCase()) {
                  return 1;
                }
              })
              .map((key) => {
                if (typeof ACCORDION_DETAILS[key] === "string") {
                  return (
                    <div
                      className={`${styles["accordion-one-line"]} ${
                        styles[`${key}`]
                      }`}
                    >
                      <div className={styles.left}>
                        {replaceUnderscore(key)}
                      </div>
                      <div className={styles.line}></div>
                      <div className={styles.right}>
                        {!Number(ACCORDION_DETAILS[key])
                          ? ACCORDION_DETAILS[key]
                          : Number(ACCORDION_DETAILS[key]).toFixed(2)}
                      </div>
                    </div>
                  );
                } else if (typeof ACCORDION_DETAILS[key] === "object") {
                  return Object.keys(ACCORDION_DETAILS[key]).map((lvl2key) => (
                    <div
                      className={`${styles["accordion-one-line"]} ${
                        styles[`${lvl2key}`]
                      }`}
                    >
                      <div className={styles.left}>
                        {replaceUnderscore(lvl2key)}
                      </div>
                      <div className={styles.line}></div>
                      <div className={styles.right}>
                        {!Number(ACCORDION_DETAILS[key][lvl2key])
                          ? ACCORDION_DETAILS[key][lvl2key]
                          : Number(ACCORDION_DETAILS[key][lvl2key]).toFixed(2)}
                      </div>
                    </div>
                  ));
                }
              })}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else {
    return <div className={styles["one-content"]}>Loading</div>;
  }
};

export default TabPanelContent;
