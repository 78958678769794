import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { Button } from "@material-ui/core";
import { AuthContext } from "components/AuthContext";
import useAlert from "irisUI/alert/hoc/useAlert";
import { getDeviceStatus, rebootDevice } from "utils/api/patrolAPI";
import { makeStyles } from "@material-ui/styles";
import audioFile from "../../assets/audio/device_offline_warning_sound_edited.mp3";
import useConfirm from "irisUI/confirm/hoc/useConfirm";

const useStyle = makeStyles({
  btn: {
    "&:disabled": {
      backgroundColor: "#00cc00",
      color: "#fff",
    },
  },
});

const DEVICE_STATUS = {
  /** loading because of calling API */
  LOADING: "Loading",
  /** API returns good response and device is marked as "active": true */
  ONLINE: "Online",
  /** API returns good response and device is marked as "active": false */
  OFFLINE: "Offline",
  /** before calling API or API did not return "ok" response */
  "N/A": "N / A",
  /** reboot request is sent and awaiting device back on line in 3 minutes */
  REBOOTING: "Rebooting",
};
function DeviceIndicator() {
  const { deviceInfo } = useContext(AuthContext);

  const classes = useStyle();
  //   device_sn: "59dcb6756f261dc4"
  //   id: 13
  //   tag: "TEST - Lucien"

  /** audo object that plays warning sound */
  const audioRef = useRef(new Audio(audioFile));

  /** determiend whehter device was marked as online */
  const wasOnlineRef = useRef(false);

  const [deviceStatus, setDeviceStatus] = useState(DEVICE_STATUS["N/A"]);

  const { showAlert } = useAlert();
  const { isConfirmed } = useConfirm();

  /**
   * @summary callback function for device button, when this function is called, send a request to restart device
   *
   * @description upon a request to send to reboot a device, this function updates the value of the deviceStatus so
   * that the toggle button would be disabled for 3 minutes, preventing user from rebooting the same dvice before the
   * server has enough information to mark it as online.
   *
   * @description set request to server to restart the device, if request succeed, disabled the toggle button for
   * 3 minutes
   */
  const restartDevice = useCallback(() => {
    try {
      rebootDevice(deviceInfo.id).then((response) => {
        const { success } = response;

        if (success) {
          setDeviceStatus(DEVICE_STATUS.REBOOTING);
          showAlert("Rebooting device, it could take up to 3 minutes");
          process.env.NODE_ENV === "development" &&
            console.log("button is colling down: ");
        }
      });
    } catch (error) {
      showAlert("Error in rebooting device");
    }
  }, [deviceInfo, setDeviceStatus, showAlert]);

  const playWarningSound = useCallback(() => {
    const { current: audio } = audioRef;
    if (audio) {
      audio.loop = true;
      audio.load();
      audio.play();
    }
  }, []);

  const pauseWarningSound = useCallback(() => {
    const { current: audio } = audioRef;
    if (audio) {
      audio.pause();
    }
  }, []);

  /**
   * @summary get device status every minute
   *
   * @description onMounted, fetch device status, then fetch the same info in every minutes.
   *
   * When it is awaiting response, the device status is marked as "Loading".
   *
   * When response is returned, check the value of "active"
   *
   * if it is true, mark device as "Online", otherwise, mark as "Offline"
   *
   * When error is returned, mark device status as N/A.
   *
   */
  useEffect(() => {
    if (!deviceInfo) return;

    const INFO = "[DeviceIndicator.tsx] [useEffect], on device CHANGED";
    const MS = 1 * 60 * 1000;
    // const MS = 1 * 30 * 1000;

    process.env.NODE_ENV === "development" &&
      console.log(`${INFO}, is CALLED: `, "DEVICE INFO", deviceInfo);

    const fetchDeviceStatus = () => {
      process.env.NODE_ENV === "development" &&
        console.log(
          `${INFO}, getting device status: `,
          "DEVICE INFO",
          deviceInfo,
          "time",
          new Date().toJSON()
        );
      setDeviceStatus((prev) => {
        if (prev === DEVICE_STATUS.REBOOTING) {
          return DEVICE_STATUS.REBOOTING;
        } else {
          return DEVICE_STATUS.LOADING;
        }
      });
      getDeviceStatus(deviceInfo.id)
        .then((deviceStatus) => {
          process.env.NODE_ENV === "development" &&
            console.log(
              `${INFO}, INTERVAL got device STATUS: `,
              "DEVICE STATUS",
              deviceStatus
            );
          if (deviceStatus?.active) {
            // mark device as "Online"
            setDeviceStatus(DEVICE_STATUS.ONLINE);
            // mark was online as true
            wasOnlineRef.current = true;
          } else if (deviceStatus?.active === false) {
            setDeviceStatus((prev) => {
              if (prev === DEVICE_STATUS.REBOOTING) {
                // keep status as "Rebooting" so that the toggle button would
                // be disabled, prevent user to press the button within 3 minutes
                // (excessively rebooting the same device)
                return DEVICE_STATUS.REBOOTING;
              }
              return DEVICE_STATUS.OFFLINE;
            });
          }
        })
        .catch((e) => {
          setDeviceStatus(DEVICE_STATUS["N/A"]);
        });
    };

    fetchDeviceStatus();
    let interval = setInterval(() => {
      fetchDeviceStatus();
    }, MS);
    return () => {
      process.env.NODE_ENV === "development" &&
        console.log(`${INFO}, unmounting`);
      process.env.NODE_ENV === "development" &&
        console.log(`${INFO}, cleaning is CALLED`, "INTERVAL", interval);
      if (interval) {
        process.env.NODE_ENV === "development" &&
          console.log(`${INFO}, cleaning is DONE`);
        clearInterval(interval);
      }

      pauseWarningSound();
    };
  }, [
    deviceInfo,
    showAlert,
    isConfirmed,
    restartDevice,
    playWarningSound,
    pauseWarningSound,
  ]);

  /**
   * @summary listen to the value of deviceStatus
   *
   * @description if the value of deviceStatus is updated to "Rebooting", after the toggle button is pressed
   * set a timer for 3 minutes. After that time, request the status of the current device immediately.
   *
   * else if the value of deviceStatus is Offline, check whether it was online previously, if it was, which means it was from
   * online to offline, user should be notify, by playing warning sound, and showing a confirmation modal.
   *
   * If the user confirms reboot on the modal, by pressing "Okay" button on the confirm modal, a reboot request
   * would be send
   *
   * When the modal is dismissed, the warning should should be paused.
   *
   * @see restartDevice
   */
  useEffect(() => {
    /** button cooldown timeout */
    let timeout: NodeJS.Timeout;
    const MILLIS_SECS_IN_THREE_MINUTES = 3 * 60 * 1000;
    const INFO = "[useEffect] [rebooting time out]";
    if (deviceStatus === DEVICE_STATUS.REBOOTING) {
      timeout = setTimeout(() => {
        // setDeviceStatus(DEVICE_STATUS["N/A"]);
        getDeviceStatus(deviceInfo.id)
          .then((deviceStatus) => {
            process.env.NODE_ENV === "development" &&
              console.log(
                `${INFO}, timeout got device STATUS: `,
                "DEVICE STATUS",
                deviceStatus
              );
            if (deviceStatus?.active) {
              setDeviceStatus(DEVICE_STATUS.ONLINE);
            } else if (deviceStatus?.active === false) {
              setDeviceStatus(DEVICE_STATUS.OFFLINE);
            }
          })
          .catch((e) => {
            setDeviceStatus(DEVICE_STATUS["N/A"]);
          });
      }, MILLIS_SECS_IN_THREE_MINUTES);
    } else if (wasOnlineRef.current && deviceStatus === DEVICE_STATUS.OFFLINE) {
      // was online but now is found offline
      wasOnlineRef.current = false;
      // play warning sound
      playWarningSound();

      // show confirm modal when user press the "Okay" button, a reboot
      // request would be sent
      isConfirmed("Device is offline, please reboot")
        .then((confirm) => {
          if (confirm) {
            restartDevice();
          }
        })
        .finally(() => {
          pauseWarningSound();
        });
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    deviceInfo,
    deviceStatus,
    playWarningSound,
    isConfirmed,
    pauseWarningSound,
    restartDevice,
  ]);

  /**
   * @summary function to determine whehter the toggle button should be disabeld or not
   *
   * @description if isCollingDown is true, the button should be disabled - preventing unnecessary rebooting
   * if the device status is either LOADING or ONLINE, should also be disabeld - no need to be enabled
   * otherwise, the toggle button should be enabled
   *
   * @returns if true, the toggle button should be disabled, false otherwise
   */
  const isBtnDisabled = () => {
    let shouldBeDisabled = false;

    switch (deviceStatus) {
      case DEVICE_STATUS.LOADING:
      case DEVICE_STATUS.ONLINE:
      case DEVICE_STATUS.REBOOTING:
        shouldBeDisabled = true;
        break;
      default:
        shouldBeDisabled = false;
    }
    return shouldBeDisabled;
  };

  if (!deviceInfo) {
    return <span></span>;
  }
  return (
    <div style={{ display: "flex", gap: "0.175rem", alignItems: "center" }}>
      <span style={{ fontWeight: 500, fontSize: "1.25rem" }}>
        {deviceInfo.tag}
      </span>
      <Button
        color="secondary"
        classes={{
          // disabled: classes.disabled,
          disabled: classes.btn,
        }}
        variant="contained"
        onClick={restartDevice}
        disabled={isBtnDisabled()}
      >
        {deviceStatus}
      </Button>
    </div>
  );
}

export default DeviceIndicator;
