import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu, MenuItem } from "@material-ui/core";
import IrisCityLogo from "../assets/IrisCityLogo.png";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import { AuthContext } from "../components/AuthContext";
import { PROTECTED_ROUTES } from "../routes/data/protectedRoutes";
import WeatherView from "../components/weather/WeatherView";
import { OPEN_WEATHER_CITY_LIST } from "../utils/openWeatherAPI/openWeatherAPI";
import Auth from "@aws-amplify/auth";
import DeviceIndicator from "components/deviceIndicator/DeviceIndicator";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    // height: `100vh`,
    // height: "100vh",
    // width: "100vw",
    // backgroundColor: "green",
  },
  appbar: {
    height: TOOL_BAR_HEIGHT,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    height: TOOL_BAR_HEIGHT,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  siteLogo: {
    objectFit: "contain",
    height: TOOL_BAR_HEIGHT - 20,
    marginTop: "8px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    height: TOOL_BAR_HEIGHT,
    marginTop: "3px",
    borderRadius: 0,
  },
  menuText: {
    marginRight: 10,
    marginLeft: 10,
    marginTop: 3,
  },
}));

export default function HomePageLayout(props) {
  const classes = useStyles();
  // const { showPreference, setShowPreference } = useContext(HomePageContext);

  const { contextUsername, setContextUsername } = useContext(AuthContext);
  const [menuAnchor, setMenuAnchor] = useState(false);

  // const toggleOpenDrawer = () => {
  //   const { toggleDrawer } = props;
  //   // toggleDrawer();
  // };

  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      alert("Error in signing out from AWS");
    } finally {
      setMenuAnchor(false);
      setContextUsername();
    }
  };

  // const renderMenu = (
  //   <Menu
  //     anchorEl={menuAnchor}
  //     getContentAnchorEl={null}
  //     anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     keepMounted
  //     open={menuAnchor !== false}
  //     onClose={handleMenuClose}
  //   >
  //     {props.history?.location?.pathname === "/patrol" && (
  //       <MenuItem
  //         onClick={() => {
  //           props.history.push(PROTECTED_ROUTES.patrolForm.path);
  //           setMenuAnchor(false);
  //         }}
  //       >
  //         Home
  //       </MenuItem>
  //     )}
  //     {/* <MenuItem onClick={handlePreference}>Preferences</MenuItem> */}
  //     <MenuItem onClick={handleLogout}>Logout</MenuItem>
  //   </Menu>
  // );
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} elevation={1}>
        <Toolbar className={classes.toolbar}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={IrisCityLogo} className={classes.siteLogo} alt="IrisGO" />
            <div style={{ marginLeft: "0.5rem" }}>
              <WeatherView cityCode={OPEN_WEATHER_CITY_LIST["Vaughan"].code} />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DeviceIndicator />
            <Typography variant="h6" className={classes.menuText}>
              {contextUsername}
            </Typography>
            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setMenuAnchor(event.currentTarget)}
              color="inherit"
              className={classes.menuButton}
            >
              <Typography variant="h6" className={classes.menuText}>
                {contextUsername}
              </Typography>
              <MenuIcon />
            </IconButton> */}
          </div>
          {/* {renderMenu} */}
        </Toolbar>
      </AppBar>

      <div
        style={{
          position: "absolute",
          top: TOOL_BAR_HEIGHT,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
