import { createContext } from "react";
import { useReducer, useEffect } from "react";

import moment from "moment";
import { fetchMDSS } from "utils/api/patrolAPI";

export const MDSSContext = createContext();

const initialState = {
  patrolType: "summer",
  // renderedPage:"",
  showRoadCondition: false,
  showRoadAlert: false,
  MDSSData: null,
  error: null,
};

const actions = {
  updatePatrolType: "update patrol type",
  updateShowRoadCondition: "update show road condition",
  updateShowRoadAlert: "update show road alert",
  updateData: "update source data",
  updateError: "update error",
};

const MDSSReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.updatePatrolType:
      return { ...state, patrolType: payload };
    case actions.updateShowRoadCondition:
      return { ...state, showRoadCondition: payload };
    case actions.updateShowRoadAlert:
      return { ...state, showRoadAlert: payload };

    case actions.updateData:
      return { ...state, MDSSData: payload };
    case actions.updateError:
      return { ...state, error: payload };
    default:
      return { ...state };
  }
};

export const MDSSProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MDSSReducer, initialState);

  const handleUpdatePatrolType = (actionPayload) => {
    dispatch({ type: actions.updatePatrolType, payload: actionPayload });
  };

  const handleShowRoadCondition = (actionPayload) => {
    dispatch({ type: actions.updateShowRoadCondition, payload: actionPayload });
  };

  const handleShowRoadAlert = (actionPayload) => {
    dispatch({ type: actions.updateShowRoadAlert, payload: actionPayload });
  };

  const updateData = (actionPayload) => {
    dispatch({ type: actions.updateData, payload: actionPayload });
  };

  const updateError = (actionPayload) => {
    dispatch({ type: actions.updateError, payload: actionPayload });
  };

  useEffect(() => {
    // const controller = new AbortController();

    const getMDSS = async (startTime, endTime) => {
      try {
        const customizedData = await fetchMDSS(startTime, endTime);

        // console.log(
        //   "data updated from mdss context ",
        //   new Date(),
        //   customizedData
        // );
        updateData(customizedData);
      } catch (err) {
        updateError(err);
      }
    };

    const currentTime = moment().format("MM/DD/YYYY HH:00:00");
    const eightHoursLater = moment(currentTime)
      .add(8, "h")
      .format("MM/DD/YYYY HH:00:00");

    getMDSS(currentTime, eightHoursLater);

    //set time interval to check if fetch data needs to be called
    let fetchInterval = null;

    //every time when fetch data, take down the time
    let startOfNextCheckPoint = moment().add(1, "hour").startOf("hour");

    if (state.patrolType === "winter") {
      fetchInterval = setInterval(() => {
        const checkedAt = moment();

        //check if checkedAt is after next hour start time or excatly at next hour start time
        if (
          moment(checkedAt).isAfter(startOfNextCheckPoint) ||
          checkedAt.minutes() === 0
        ) {
          const InputCur = moment().format("MM/DD/YYYY HH:00:00");
          const InputEightHLater = moment(InputCur)
            .add(8, "h")
            .format("MM/DD/YYYY HH:00:00");

          getMDSS(InputCur, InputEightHLater);
          console.log("fetch data at ", new Date());
        }

        //every time after checking, update the next check point
        //07:59:59:999 => 08:00:00:000, 07:00:00:000 => 08:00:00:000
        startOfNextCheckPoint = checkedAt.add(1, "hour").startOf("hour");
      }, 60 * 1000);
    } else if (state.patrolType === "summer") {
      clearInterval(fetchInterval);
    }

    return () => {
      clearInterval(fetchInterval);
      // controller.abort();
    };
  }, [state.patrolType]);

  return (
    <MDSSContext.Provider
      value={{
        ...state,
        handleUpdatePatrolType,
        handleShowRoadCondition,
        handleShowRoadAlert,
      }}
    >
      {children}
    </MDSSContext.Provider>
  );
};
