import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
  root: {
    padding: "1.5em 0",
    display: "flex",
    alignItems: "flex-end",
    "& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiSvgIcon-root": {
      color: "#fff",
    },
    borderBottom: "2px dashed rgba(255,255,255, 0.6)",
    boxSizing: "border-box",
  },

  formControl: {
    // marginRight: 20,
    // minWidth: 150,
    // width: "100%",

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #fff",
    },
  },

  formControlSm: {
    minWidth: 50,

    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #fff",
    },
  },
  button: {
    color: "#fff",
  },

  menu: {
    "& .MuiMenu-paper": {
      background: "rgb(70,70,70)",
      color: "#fff",
      // "& .MuiList-root": {
      //   color: "red",

      //   "& .MuiListItem-button:hover": {
      //     backgroundColor: "green",
      //   },
      // },

      "& .MuiList-root .MuiListItem-button:hover": {
        backgroundColor: "rgb(30,30,30)",
      },
    },
  },
}));

export default function LabelSelectionView({
  labelIndex,
  labels,
  ancesstorChain = [0],
  removeLabelType,
  updateSelectedLabel: onLabelSelected,
}) {
  const classes = useStyles();

  const selectedLabelRef = useRef(undefined);

  const [ancesstorIds, setAncesstorIds] = useState([0]);

  useEffect(() => {
    setAncesstorIds(ancesstorChain);
  }, [ancesstorChain]);
  /** style for the selection menus */
  const MenuProps = {
    className: classes.menu,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const handleSelect = (index) => (event) => {
    // const a = parentIds.concat(event.target.value);
    // setAncesstorIds(a);
    const { value } = event.target;
    if (value < 0) return;
    // debugger;
    const nextIndex = index + 1;
    const updatedPids = ancesstorIds.splice(0, nextIndex);
    updatedPids[++index] = value;
    setAncesstorIds(updatedPids);

    const children = labels.filter(({ parent }) => parent === value);
    if (children.length === 0) {
      selectedLabelRef.current = labels.find(({ id }) => id === value);

      if (selectedLabelRef.current) {
        onLabelSelected(labelIndex, [...updatedPids], selectedLabelRef.current);
      }
    } else {
      selectedLabelRef.current = undefined;
      onLabelSelected(labelIndex, [...updatedPids], undefined);
    }
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={() => removeLabelType(labelIndex)}
        size="small"
        // color="secondary"
        className={classes.button}
      >
        <DeleteIcon style={{ fontSize: 40 }} />
      </IconButton>
      <Grid container spacing={2}>
        {ancesstorIds.map((parentId, index) => {
          const filtered = labels
            .filter(({ parent }) => parent === parentId)
            .sort((a, b) => {
              const { name: nameA } = a;
              const { name: nameB } = b;
              if (nameA > nameB) return 1;
              else if (nameA < nameB) return -1;
              return 0;
            });
          if (filtered.length > 0) {
            return (
              <Grid item xs={3} key={index}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Type {index + 1}</InputLabel>
                  <Select
                    value={ancesstorIds[index + 1] || -1}
                    onChange={handleSelect(index)}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={-1}>N / A</MenuItem>
                    {filtered.map(({ name, id }) => (
                      <MenuItem value={id} key={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          } else {
            const rootLabel = labels.find(
              ({ id }) => id === ancesstorIds[ancesstorIds.length - 1]
            );
            console.log(`rootLabel`, rootLabel);
            if (rootLabel) {
              const { range_title } = rootLabel;
              return (
                <Grid item xs={2} key={index}>
                  <LabelRateSelectionView
                    rangeTitle={range_title}
                    onRangeTitleChange={(value) => {
                      const { current } = selectedLabelRef;
                      if (current) {
                        current.range = value;
                        onLabelSelected(
                          labelIndex,
                          [...ancesstorIds],
                          selectedLabelRef.current
                        );
                      }
                    }}
                  />
                </Grid>
              );
            }

            return null;
          }
        })}
      </Grid>
    </div>
  );
}

const NUMBER_RANGE_TITLES = [
  "Speed Limits",
  "# bags",
  "level",
  "sqft",
  "ft",
  "Road #",
];

const SELECTIONS_RANGE_TITLES = ["Severity"];

const NA_RANGE_TITLES = ["N/A"];

function LabelRateSelectionView({ rangeTitle, onRangeTitleChange }) {
  const classes = useStyles();
  const isNumericRange =
    typeof rangeTitle === "string" &&
    NUMBER_RANGE_TITLES.indexOf(rangeTitle.trim().toLowerCase()) > -1;
  const [selectedRange, setselectedRange] = useState("");

  useEffect(() => {
    if (isNumericRange) {
      setselectedRange(1);
    }
  }, [rangeTitle]);

  const [range, setRange] = useState([]);

  // input number
  if (isNumericRange) {
    return (
      <FormControl className={classes.formControlSm}>
        <TextField
          label={rangeTitle}
          value={selectedRange}
          onChange={(event) => {
            const v = Number(event.target.value);
            setselectedRange(v);
            if (!isNaN(v)) onRangeTitleChange(v);
          }}
          inputProps={{
            type: "number",
            step: "0.1",
          }}
        />
      </FormControl>
    );
  } else if (Array.isArray(rangeTitle)) {
    setRange(rangeTitle);
    return (
      <FormControl className={classes.formControlSm}>
        <TextField
          select
          label="Range"
          value={selectedRange}
          onChange={(value) => onRangeTitleChange(value)}
        >
          {range.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }
  return null;
}
