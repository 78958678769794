export const DEMO_LOOKUP_RESULT = {
  success: true,
  message: "",
  result: {
    device_info: [
      { device_sn: "b702a025a5da5c2f", tag: "demo-device-0", id: 15 },
      { device_sn: "f285a8fed98f130c", tag: "demo-device-1", id: 29 },
      { device_sn: "7a3c0cf025806b20", tag: "demo-device-2", id: 5 },
      { device_sn: "a685d3d06b0798fa", tag: "demo-device-3", id: 8 },
      { device_sn: "948e69d58be9fb7f", tag: "demo-device-4", id: 35 },
      { device_sn: "f2cbd0cfabf90d92", tag: "demo-device-5", id: 9 },
      { device_sn: "2cddb85b4aa63326", tag: "demo-device-6", id: 14 },
      { device_sn: "5233", tag: "demo-device-7", id: 38 },
      { device_sn: "17cdb4c8259f4072", tag: "demo-device-8", id: 68 },
      { device_sn: "4347854219ff4792", tag: "demo-device-9", id: 63 },
      { device_sn: "b5f4d005bb61be8c", tag: "demo-device-10", id: 34 },
      { device_sn: "0", tag: "demo-device-11", id: 61 },
      { device_sn: "6e6067e12ec0d678", tag: "demo-device-12", id: 11 },
      { device_sn: "4873610a065a097f", tag: "demo-device-13", id: 21 },
      { device_sn: "b6e303d3bd4c8af0", tag: "demo-device-14", id: 20 },
      { device_sn: "9a048915e2ea4138", tag: "demo-device-15", id: 70 },
      { device_sn: "871d6b8e39b89d8a", tag: "demo-device-16", id: 64 },
      { device_sn: "0", tag: "demo-device-17", id: 43 },
      { device_sn: "0", tag: "demo-device-18", id: 46 },
      { device_sn: "0", tag: "demo-device-19", id: 41 },
      { device_sn: "97362c10f0ec4b20", tag: "demo-device-20", id: 65 },
      { device_sn: "bebc85bfaf36ad58", tag: "demo-device-21", id: 36 },
      { device_sn: "caace10afb2d7fa2", tag: "demo-device-22", id: 67 },
      { device_sn: "79011bbdee222511", tag: "demo-device-23", id: 28 },
      { device_sn: "377ef7d76c556e4f", tag: "demo-device-24", id: 39 },
      { device_sn: "37c46ee165ee4580", tag: "demo-device-25", id: 44 },
      { device_sn: "0", tag: "demo-device-26", id: 42 },
      { device_sn: "0", tag: "demo-device-27", id: 45 },
      { device_sn: "0", tag: "demo-device-28", id: 49 },
      { device_sn: "78b92fe039e7b450", tag: "demo-device-29", id: 1 },
      { device_sn: "f568b8af961abed3", tag: "demo-device-30", id: 6 },
      { device_sn: "0", tag: "demo-device-31", id: 47 },
      { device_sn: "dcf50f6b9fe1852a", tag: "demo-device-32", id: 50 },
      { device_sn: "0f279dc7752dded2", tag: "demo-device-33", id: 69 },
      { device_sn: "59dcb6756f261dc4", tag: "demo-device-34", id: 13 },
      { device_sn: "9d09e8f29952ae53", tag: "demo-device-35", id: 71 },
      { device_sn: "3737ae13ffb34a83", tag: "demo-device-36", id: 40 },
      { device_sn: "ac1194a178ea0d6e", tag: "demo-device-37", id: 33 },
      { device_sn: "be2e04d1d4637828", tag: "demo-device-38", id: 32 },
      { device_sn: "6f5c5c07ce732615", tag: "demo-device-39", id: 7 },
      { device_sn: "89cfdea28c67f367", tag: "demo-device-40", id: 10 },
      { device_sn: "0", tag: "demo-device-41", id: 54 },
      { device_sn: "45650f2285dbdf03", tag: "demo-device-42", id: 48 },
      { device_sn: "410ce8caaa54cbb8", tag: "demo-device-43", id: 55 },
      { device_sn: "0", tag: "demo-device-44", id: 52 },
      { device_sn: "44e68ef70d4436fe", tag: "demo-device-45", id: 31 },
      { device_sn: "32af76879b1a0564", tag: "demo-device-46", id: 72 },
      { device_sn: "70e3c03762848467", tag: "demo-device-47", id: 4 },
      { device_sn: "b66ee5fb8ef9217a", tag: "demo-device-48", id: 27 },
      { device_sn: "0475caafa6b087f6", tag: "demo-device-49", id: 30 },
      { device_sn: "3a3fe091bd2c9cf4", tag: "demo-device-50", id: 51 },
      { device_sn: "58972c9d83f72b35", tag: "demo-device-51", id: 66 },
      { device_sn: "5f530efea2e937af", tag: "demo-device-52", id: 56 },
      { device_sn: "bbeb01d25a1ea563", tag: "demo-device-53", id: 59 },
      { device_sn: "84c0317517b4980e", tag: "demo-device-54", id: 53 },
      { device_sn: "a0ff89450fbe6d51", tag: "demo-device-55", id: 58 },
      { device_sn: "ab270514bbe740c3", tag: "demo-device-56", id: 57 },
      { device_sn: "9ae90fb50f651459", tag: "demo-device-57", id: 60 },
      { device_sn: "984a4b79412494ec", tag: "demo-device-58", id: 62 },
    ],
    label_type: [
      {
        id: 2,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Pothole Paved Surface",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 3,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Pothole Non-paved",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 4,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Pothole Shoulder",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 5,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Shoulder Drop-off",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 6,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Crack",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 7,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Debris",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 8,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Bridge Deck Spall",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 9,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Road Discontinuity",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 10,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Snow Accomulation",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 11,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Encroachment",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 12,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Signage",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 13,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Rutting",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 14,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Bad",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 15,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Patch",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 16,
        rating_range: 0,
        parent: 38,
        enabled: "N",
        name: "Trash on Sidewalk/Road",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 17,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Dead Animal",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 18,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Road Hazard",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 19,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Garbage Set-Out Issue",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 20,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Unsafe Waste Issue",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 21,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Ongoing Residential Construction Works",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 22,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Graffiti",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 23,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Heavy Undergrowth",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 24,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Manhole Related Issue",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 25,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Pavement Marking Issue",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 26,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Blue Bin Issue",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 27,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Inoperative Vehicle",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 28,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Construction Debris Issue",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 29,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Gold box resident",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 30,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Ongoing road works",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 31,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Shopping cart",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 32,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Construction warning sign",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 33,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Bulk items set out",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 34,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Unkept lawn - Long grass & weeds",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 35,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Abandoned appliance",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 36,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Patio commercial activity",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 37,
        rating_range: 0,
        parent: 39,
        enabled: "N",
        name: "Litter Overflow",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 38,
        rating_range: 0,
        parent: 0,
        enabled: "N",
        name: "RRI",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 39,
        rating_range: 0,
        parent: 0,
        enabled: "N",
        name: "MMS",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 41,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Graffiti",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 42,
        rating_range: 3,
        parent: 41,
        enabled: "Y",
        name: "Graffiti - Normal",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 43,
        rating_range: 3,
        parent: 41,
        enabled: "Y",
        name: "Graffiti - Hate/Racist",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 44,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Trash Accumulation",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 45,
        rating_range: 3,
        parent: 44,
        enabled: "Y",
        name: "Trash Accumulation - Roadway",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 46,
        rating_range: 3,
        parent: 44,
        enabled: "Y",
        name: "Trash Accumulation - Intersection",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 47,
        rating_range: 3,
        parent: 44,
        enabled: "Y",
        name: "Trash Accumulation - Sidewalk",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 48,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Heavy Undergrowth (18 inches)",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 49,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Solar Powered Sign",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 50,
        rating_range: 0,
        parent: 49,
        enabled: "Y",
        name: "Solar Powered Sign - Fixed",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 51,
        rating_range: 0,
        parent: 49,
        enabled: "Y",
        name: "Solar Powered Sign - Mobile",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 52,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Bus Shelter",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 53,
        rating_range: 0,
        parent: 52,
        enabled: "Y",
        name: "Bus Shelter - Good condition",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 54,
        rating_range: 3,
        parent: 52,
        enabled: "Y",
        name: "Bus Shelter - Vandalized/Damaged",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 55,
        rating_range: 3,
        parent: 52,
        enabled: "Y",
        name: "Bus Shelter - Trash Accumulation",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 56,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Shared Bike Station",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 57,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Shopping Cart",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 58,
        rating_range: 0,
        parent: 40,
        enabled: "N",
        name: "Bulk Item Set Out",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 59,
        rating_range: 0,
        parent: 58,
        enabled: "N",
        name: "Bulk Item Set Out - Mattress",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 60,
        rating_range: 0,
        parent: 58,
        enabled: "N",
        name: "Bulk Item Set Out - Furniture",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 61,
        rating_range: 0,
        parent: 58,
        enabled: "N",
        name: "Bulk Item Set Out - Applicance",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 62,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Patio Commercial Activity",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 63,
        rating_range: 0,
        parent: 40,
        enabled: "Y",
        name: "Repaired Defects",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 64,
        rating_range: 0,
        parent: 63,
        enabled: "Y",
        name: "Repaired Defects - Crack",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 65,
        rating_range: 0,
        parent: 63,
        enabled: "Y",
        name: "Repaired Defects - Pavement",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 399,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "Regulatory MMS",
        range_title: "Category",
        city_id: [8],
        service_day: [],
      },
      {
        id: 129,
        rating_range: 0,
        parent: 128,
        enabled: "Y",
        name: "Alligator Cracking - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 132,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Bleeding",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 138,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Joint Reflection Cracking",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 136,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Depression",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 134,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Bumps and Sags",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 140,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Long & Trans Cracking",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 83,
        rating_range: 15,
        parent: 82,
        enabled: "Y",
        name: "Recycling Bag - Blue (Choose # bags)",
        range_title: "# bags",
        city_id: [],
        service_day: [],
      },
      {
        id: 84,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Illegal Dumping",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 85,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Bulk Item Set Out",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 86,
        rating_range: 0,
        parent: 85,
        enabled: "Y",
        name: "Bulk Item Set Out - Appliance",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 87,
        rating_range: 0,
        parent: 85,
        enabled: "Y",
        name: "Bulk Item Set Out - Furniture",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 88,
        rating_range: 0,
        parent: 85,
        enabled: "Y",
        name: "Bulk Item Set Out - Mattress with Bed Bug Cover",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 89,
        rating_range: 0,
        parent: 85,
        enabled: "Y",
        name: "Bulk Item Set Out - Mattress without Bed Bug Cover",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 90,
        rating_range: 0,
        parent: 85,
        enabled: "Y",
        name: "Bulk Item Set Out - Other",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 91,
        rating_range: 15,
        parent: 82,
        enabled: "Y",
        name: "Paper Bag (Choose # bags)",
        range_title: "# bags",
        city_id: [],
        service_day: [],
      },
      {
        id: 92,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Garbage Pad",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 93,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Concrete Open Top Litter",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 94,
        rating_range: 0,
        parent: 93,
        enabled: "Y",
        name: "Concrete Open Top Litter - Regular",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 95,
        rating_range: 0,
        parent: 93,
        enabled: "Y",
        name: "Concrete Open Top Litter - Full",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 96,
        rating_range: 0,
        parent: 93,
        enabled: "Y",
        name: "Concrete Open Top Litter - Damaged/ Vandalized",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 97,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Litter Jubilee",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 98,
        rating_range: 0,
        parent: 97,
        enabled: "Y",
        name: "Litter Jubilee - Regular",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 99,
        rating_range: 0,
        parent: 97,
        enabled: "Y",
        name: "Litter Jubilee - Full",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 100,
        rating_range: 0,
        parent: 97,
        enabled: "Y",
        name: "Litter Jubilee - Damaged/ Vandalized",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 101,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Blue Bin",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 102,
        rating_range: 5,
        parent: 101,
        enabled: "Y",
        name: "Blue Bin - Small size (Choose # bins)",
        range_title: "# bins",
        city_id: [],
        service_day: [],
      },
      {
        id: 103,
        rating_range: 5,
        parent: 101,
        enabled: "Y",
        name: "Blue Bin - Medium size (Choose # bins)",
        range_title: "# bins",
        city_id: [],
        service_day: [],
      },
      {
        id: 104,
        rating_range: 5,
        parent: 101,
        enabled: "Y",
        name: "Blue Bin - Large size (Choose # bins)",
        range_title: "# bins",
        city_id: [],
        service_day: [],
      },
      {
        id: 105,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Green Cart",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 106,
        rating_range: 5,
        parent: 105,
        enabled: "Y",
        name: "Green Cart - Small size (MiHi) (Choose # bins)",
        range_title: "# bins",
        city_id: [],
        service_day: [],
      },
      {
        id: 107,
        rating_range: 5,
        parent: 105,
        enabled: "Y",
        name: "Green Cart - Medium size (Choose # bins)",
        range_title: "# bins",
        city_id: [],
        service_day: [],
      },
      {
        id: 108,
        rating_range: 5,
        parent: 105,
        enabled: "Y",
        name: "Green Cart - Large size (Choose # bins)",
        range_title: "# bins",
        city_id: [],
        service_day: [],
      },
      {
        id: 109,
        rating_range: 0,
        parent: 82,
        enabled: "Y",
        name: "Black Garbage Bag",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 110,
        rating_range: 15,
        parent: 109,
        enabled: "Y",
        name: "Black Garbage Bag - Multi residence (Choose # bags)",
        range_title: "# bags",
        city_id: [],
        service_day: [],
      },
      {
        id: 111,
        rating_range: 15,
        parent: 109,
        enabled: "Y",
        name: "Black Garbage Bag - Single residence (Choose # bags)",
        range_title: "# bags",
        city_id: [],
        service_day: [],
      },
      {
        id: 67,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Pothole paved surface",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 142,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Polished Aggregate",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 144,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Railroad Crossings",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 69,
        rating_range: 3,
        parent: 66,
        enabled: "N",
        name: "Pothole shoulder",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 68,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Pothole non-paved surface",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 127,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "PCI",
        range_title: "level",
        city_id: [1, 2, 3, 5, 6, 7],
        service_day: [],
      },
      {
        id: 82,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "Waste",
        range_title: "level",
        city_id: [3, 7],
        service_day: [],
      },
      {
        id: 66,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "MMS",
        range_title: "level",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 146,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Shoving",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 148,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Swell",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 150,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "Signs",
        range_title: "level",
        city_id: [5],
        service_day: [],
      },
      {
        id: 152,
        rating_range: 0,
        parent: 151,
        enabled: "Y",
        name: "MTO Overhead Gantry-mounted VMS",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 153,
        rating_range: 0,
        parent: 151,
        enabled: "Y",
        name: "MTO Roadside-mounted Cantilever VMS",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 154,
        rating_range: 0,
        parent: 151,
        enabled: "Y",
        name: "MTO Combination for the Queue Warning System",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 409,
        rating_range: 0,
        parent: 401,
        enabled: "Y",
        name: "Surface Discontinuities",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 45, class_id: 6, service_day: 14, label_type: 409 },
          { id: 44, class_id: 5, service_day: 14, label_type: 409 },
          { id: 43, class_id: 4, service_day: 14, label_type: 409 },
          { id: 42, class_id: 3, service_day: 14, label_type: 409 },
          { id: 41, class_id: 2, service_day: 14, label_type: 409 },
          { id: 40, class_id: 6, service_day: 21, label_type: 409 },
          { id: 39, class_id: 5, service_day: 21, label_type: 409 },
          { id: 38, class_id: 4, service_day: 21, label_type: 409 },
          { id: 37, class_id: 3, service_day: 7, label_type: 409 },
          { id: 36, class_id: 2, service_day: 2, label_type: 409 },
        ],
      },
      {
        id: 70,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Shoulder Drop-off",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 71,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Crack",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 72,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Debris",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 73,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Bridge Deck Spall",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 74,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Road Discontinuity",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 75,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Snow Accumulation",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 76,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Encroachment",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 77,
        rating_range: 0,
        parent: 66,
        enabled: "Y",
        name: "Signage Issue",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 78,
        rating_range: 3,
        parent: 77,
        enabled: "Y",
        name: "Signage Illegible",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 79,
        rating_range: 3,
        parent: 77,
        enabled: "Y",
        name: "Signage Orientation",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 80,
        rating_range: 3,
        parent: 77,
        enabled: "Y",
        name: "Signage Obscured",
        range_title: "Severity ",
        city_id: [],
        service_day: [],
      },
      {
        id: 81,
        rating_range: 0,
        parent: 77,
        enabled: "Y",
        name: "Signage Missing",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 112,
        rating_range: 5,
        parent: 82,
        enabled: "Y",
        name: "Garbage Bin",
        range_title: "# bins",
        city_id: [],
        service_day: [],
      },
      {
        id: 40,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "Right Of Way (ROW)",
        range_title: "level",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 374,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "Traffic Signs",
        range_title: "level",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 371,
        rating_range: 0,
        parent: 149,
        enabled: "Y",
        name: "Weathering / Raveling - Low Severity",
        range_title: "sqft",
        city_id: [1, 2, 3, 4, 5, 6, 7],
        service_day: [],
      },
      {
        id: 395,
        rating_range: 120,
        parent: 374,
        enabled: "Y",
        name: "Maximum Speed Limit",
        range_title: "Legend",
        city_id: [5],
        service_day: [],
      },
      {
        id: 117,
        rating_range: 0,
        parent: 67,
        enabled: "N",
        name: "Pothole Paved Surface Repair (Area>600cm2 and Depth>8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 118,
        rating_range: 0,
        parent: 67,
        enabled: "N",
        name: "Pothole Paved Surface Watch (Area<=600cm2 or Depth<=8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 119,
        rating_range: 0,
        parent: 68,
        enabled: "N",
        name: "Pothole Non-Paved Surface Repair (Area>1500cm2 and Depth>8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 120,
        rating_range: 0,
        parent: 68,
        enabled: "N",
        name: "Pothole Non-Paved Surface Watch (Area<=1500cm2 or Depth<=8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 121,
        rating_range: 0,
        parent: 116,
        enabled: "N",
        name: "Paved or Non-paved Shoulder Repair (Area>1500cm2 and Depth>8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 122,
        rating_range: 0,
        parent: 116,
        enabled: "N",
        name: "Paved or Non-paved Shoulder Watch (Area<=1500cm2 or Depth<=8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 123,
        rating_range: 0,
        parent: 70,
        enabled: "N",
        name: "Shoulder Drop-Off Repair (Depth>8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 124,
        rating_range: 0,
        parent: 70,
        enabled: "N",
        name: "Shoulder Drop-Off Watch (Depth<=8cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 125,
        rating_range: 0,
        parent: 71,
        enabled: "N",
        name: "Crack Repair (width and depth > 5 cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 126,
        rating_range: 0,
        parent: 71,
        enabled: "N",
        name: "Crack Watch (width or depth <= 5 cm)",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 389,
        rating_range: 0,
        parent: 77,
        enabled: "Y",
        name: "Damaged Sign",
        range_title: "level",
        city_id: [1],
        service_day: [],
      },
      {
        id: 375,
        rating_range: 110,
        parent: 374,
        enabled: "Y",
        name: "Speed Signs - Rb-1",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 382,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "No Stopping Sign - Rb-55",
        range_title: "N/A",
        city_id: [3, 7],
        service_day: [],
      },
      {
        id: 383,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "BEGINS Tab Sign - Rb-84t",
        range_title: "N/A",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 384,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "KM/H Tab Sign - Rb-7t",
        range_title: "N/A",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 128,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Alligator Cracking",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 385,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "ENDS Tab Sign - Rb-85t",
        range_title: "N/A",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 133,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Block Cracking",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 137,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Edge Cracking",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 135,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Corrugation",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 139,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Lane/Shoulder Drop Off",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 141,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Patching & Util Cut Patching",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 143,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Potholes",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 145,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Rutting",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 147,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Slippage Cracking",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 149,
        rating_range: 0,
        parent: 127,
        enabled: "Y",
        name: "Weathering / Raveling",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 130,
        rating_range: 0,
        parent: 128,
        enabled: "Y",
        name: "Alligator Cracking - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 116,
        rating_range: 3,
        parent: 66,
        enabled: "Y",
        name: "Pothole Paved or Non-Paved Shoulder",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 370,
        rating_range: 0,
        parent: 148,
        enabled: "Y",
        name: "Swell - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 393,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "`",
        range_title: "level",
        city_id: [1, 2, 3, 4, 5, 6, 7],
        service_day: [],
      },
      {
        id: 349,
        rating_range: 0,
        parent: 141,
        enabled: "Y",
        name: "Patching & Util Cut Patching - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 113,
        rating_range: 3,
        parent: 66,
        enabled: "N",
        name: "Pothole Paved Shoulder",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 114,
        rating_range: 3,
        parent: 66,
        enabled: "N",
        name: "Pothole Non-paved Shoulder",
        range_title: "Severity",
        city_id: [],
        service_day: [],
      },
      {
        id: 386,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "Object Marker - Wa-33L",
        range_title: "N/A",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 151,
        rating_range: 0,
        parent: 150,
        enabled: "Y",
        name: "Dynamic Message Signs",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 155,
        rating_range: 0,
        parent: 151,
        enabled: "Y",
        name: "Lane Control",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 156,
        rating_range: 0,
        parent: 151,
        enabled: "Y",
        name: "Tunnel Lane Control",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 157,
        rating_range: 0,
        parent: 151,
        enabled: "Y",
        name: "Bridge Lane Control",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 158,
        rating_range: 0,
        parent: 151,
        enabled: "Y",
        name: "Portable Variable Message",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 159,
        rating_range: 0,
        parent: 150,
        enabled: "Y",
        name: "Regulatory Signs",
        range_title: "level",
        city_id: [],
        service_day: [],
      },
      {
        id: 160,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Stop (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 161,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Stop (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 162,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Stop (special oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 163,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "All-way (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 164,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Yield (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 165,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Yield (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 166,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Yield (tab sign) (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 167,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Yield (tab sign) (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 168,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Cross on Green Light Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 169,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Cross on Walk Signal Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 170,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Cross Only at Crossover",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 171,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Cross Other Side",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 172,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Cross Other Side One Leg",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 173,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Pedestrian Pushbutton",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 174,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Pedestrian Must Push button to Receive Walk Signal",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 175,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Pedestrian X (crossover)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 176,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Stop for Pedestrians (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 177,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Passing Here to crossing",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 178,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Pedestrian Pushbutton",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 179,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Speed (basic)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 180,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Km/h (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 181,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Speed (alternative)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 182,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Begins (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 183,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Speed Begins",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 184,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Speed km/h and Begins",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 185,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Speed Ahead",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 186,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "School Zone Maximum Speed",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 187,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "School Zone Maximum Speed When Flashing (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 188,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "School Zone Maximum Speed When Flashing (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 189,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Straight Through",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 387,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "Object Marker - Wa-33R",
        range_title: "N/A",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 388,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "Object Marker - Wa-33LR",
        range_title: "N/A",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 418,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Checkerboard",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 50, class_id: 6, service_day: 0, label_type: 418 },
          { id: 49, class_id: 5, service_day: 0, label_type: 418 },
          { id: 48, class_id: 4, service_day: 0, label_type: 418 },
          { id: 47, class_id: 3, service_day: 0, label_type: 418 },
          { id: 46, class_id: 2, service_day: 0, label_type: 418 },
        ],
      },
      {
        id: 372,
        rating_range: 0,
        parent: 149,
        enabled: "Y",
        name: "Weathering / Raveling - Medium Severity",
        range_title: "sqft",
        city_id: [1, 2, 3, 4, 5, 6, 7],
        service_day: [],
      },
      {
        id: 373,
        rating_range: 0,
        parent: 149,
        enabled: "Y",
        name: "Weathering / Raveling - High Severity",
        range_title: "sqft",
        city_id: [1, 2, 3, 4, 5, 6, 7],
        service_day: [],
      },
      {
        id: 396,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "No Parking",
        range_title: "Japan Sign",
        city_id: [5],
        service_day: [],
      },
      {
        id: 397,
        rating_range: 0,
        parent: 374,
        enabled: "Y",
        name: "No Stopping",
        range_title: "Japan Sign",
        city_id: [5],
        service_day: [],
      },
      {
        id: 394,
        rating_range: 120,
        parent: 374,
        enabled: "Y",
        name: "Minimum Speed Limit",
        range_title: "Legend",
        city_id: [5],
        service_day: [],
      },
      {
        id: 401,
        rating_range: 0,
        parent: 399,
        enabled: "Y",
        name: "Roadway",
        range_title: "Type",
        city_id: [8],
        service_day: [],
      },
      {
        id: 405,
        rating_range: 0,
        parent: 401,
        enabled: "Y",
        name: "Shoulder Drop-Off",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 20, class_id: 6, service_day: 30, label_type: 405 },
          { id: 19, class_id: 5, service_day: 14, label_type: 405 },
          { id: 18, class_id: 4, service_day: 7, label_type: 405 },
          { id: 17, class_id: 3, service_day: 4, label_type: 405 },
          { id: 16, class_id: 2, service_day: 4, label_type: 405 },
        ],
      },
      {
        id: 410,
        rating_range: 0,
        parent: 399,
        enabled: "Y",
        name: "Sidewalk",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 422,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Low Bridge",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 70, class_id: 6, service_day: 0, label_type: 422 },
          { id: 69, class_id: 5, service_day: 0, label_type: 422 },
          { id: 68, class_id: 4, service_day: 0, label_type: 422 },
          { id: 67, class_id: 3, service_day: 0, label_type: 422 },
          { id: 66, class_id: 2, service_day: 0, label_type: 422 },
        ],
      },
      {
        id: 427,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Stop Ahead",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 95, class_id: 6, service_day: 0, label_type: 427 },
          { id: 94, class_id: 5, service_day: 0, label_type: 427 },
          { id: 93, class_id: 4, service_day: 0, label_type: 427 },
          { id: 92, class_id: 3, service_day: 0, label_type: 427 },
          { id: 91, class_id: 2, service_day: 0, label_type: 427 },
        ],
      },
      {
        id: 431,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Wrong Way",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 115, class_id: 6, service_day: 0, label_type: 431 },
          { id: 114, class_id: 5, service_day: 0, label_type: 431 },
          { id: 113, class_id: 4, service_day: 0, label_type: 431 },
          { id: 112, class_id: 3, service_day: 0, label_type: 431 },
          { id: 111, class_id: 2, service_day: 0, label_type: 431 },
        ],
      },
      {
        id: 435,
        rating_range: 0,
        parent: 414,
        enabled: "Y",
        name: "Regulatory/Warning Sign Sec 12",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 443,
        rating_range: 0,
        parent: 435,
        enabled: "Y",
        name: "Missing Sign",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 150, class_id: 6, service_day: 30, label_type: 443 },
          { id: 149, class_id: 5, service_day: 30, label_type: 443 },
          { id: 148, class_id: 4, service_day: 30, label_type: 443 },
          { id: 147, class_id: 3, service_day: 21, label_type: 443 },
          { id: 146, class_id: 2, service_day: 14, label_type: 443 },
        ],
      },
      {
        id: 447,
        rating_range: 0,
        parent: 445,
        enabled: "Y",
        name: "Road Salt Required",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 160, class_id: 6, service_day: 1, label_type: 447 },
          { id: 159, class_id: 5, service_day: 1, label_type: 447 },
          { id: 158, class_id: 4, service_day: 0, label_type: 447 },
          { id: 157, class_id: 3, service_day: 0, label_type: 447 },
          { id: 156, class_id: 2, service_day: 0, label_type: 447 },
        ],
      },
      {
        id: 451,
        rating_range: 0,
        parent: 445,
        enabled: "Y",
        name: "Vehicle Blocking Bicycle Lane",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 180, class_id: 6, service_day: 0, label_type: 451 },
          { id: 179, class_id: 5, service_day: 0, label_type: 451 },
          { id: 178, class_id: 4, service_day: 0, label_type: 451 },
          { id: 177, class_id: 3, service_day: 0, label_type: 451 },
          { id: 176, class_id: 2, service_day: 0, label_type: 451 },
        ],
      },
      {
        id: 455,
        rating_range: 0,
        parent: 454,
        enabled: "Y",
        name: "Bridge Deck Spall",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 195, class_id: 6, service_day: 7, label_type: 455 },
          { id: 194, class_id: 5, service_day: 7, label_type: 455 },
          { id: 193, class_id: 4, service_day: 7, label_type: 455 },
          { id: 192, class_id: 3, service_day: 7, label_type: 455 },
          { id: 191, class_id: 2, service_day: 4, label_type: 455 },
        ],
      },
      {
        id: 463,
        rating_range: 0,
        parent: 458,
        enabled: "Y",
        name: "Cable Guiderail",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 225, class_id: 6, service_day: 330, label_type: 463 },
          { id: 224, class_id: 5, service_day: 330, label_type: 463 },
          { id: 223, class_id: 4, service_day: 330, label_type: 463 },
          { id: 222, class_id: 3, service_day: 330, label_type: 463 },
          { id: 221, class_id: 2, service_day: 330, label_type: 463 },
        ],
      },
      {
        id: 468,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "Journal",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 472,
        rating_range: 0,
        parent: 461,
        enabled: "Y",
        name: "Catch Basin",
        range_title: "n/a",
        city_id: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        service_day: [
          { id: 200, class_id: 6, service_day: 21, label_type: 472 },
          { id: 199, class_id: 5, service_day: 21, label_type: 472 },
          { id: 198, class_id: 4, service_day: 21, label_type: 472 },
          { id: 197, class_id: 3, service_day: 21, label_type: 472 },
          { id: 196, class_id: 2, service_day: 21, label_type: 472 },
        ],
      },
      {
        id: 476,
        rating_range: 0,
        parent: 461,
        enabled: "Y",
        name: "Manhole",
        range_title: "n/a",
        city_id: [1, 2, 3, 4, 5, 6, 7, 8],
        service_day: [
          { id: 220, class_id: 6, service_day: 15, label_type: 476 },
          { id: 219, class_id: 5, service_day: 15, label_type: 476 },
          { id: 218, class_id: 4, service_day: 15, label_type: 476 },
          { id: 217, class_id: 3, service_day: 15, label_type: 476 },
          { id: 216, class_id: 2, service_day: 15, label_type: 476 },
        ],
      },
      {
        id: 481,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Illegal Dumping",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 255, class_id: 6, service_day: 30, label_type: 481 },
          { id: 254, class_id: 5, service_day: 30, label_type: 481 },
          { id: 253, class_id: 4, service_day: 30, label_type: 481 },
          { id: 252, class_id: 3, service_day: 30, label_type: 481 },
          { id: 251, class_id: 2, service_day: 30, label_type: 481 },
        ],
      },
      {
        id: 485,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Street Sweeping",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 275, class_id: 6, service_day: 30, label_type: 485 },
          { id: 274, class_id: 5, service_day: 30, label_type: 485 },
          { id: 273, class_id: 4, service_day: 30, label_type: 485 },
          { id: 272, class_id: 3, service_day: 30, label_type: 485 },
          { id: 271, class_id: 2, service_day: 30, label_type: 485 },
        ],
      },
      {
        id: 190,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Straight Through with Specified Times",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 191,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Right Turn",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 192,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Right Turn with Specified Times",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 193,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Left Turn",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 194,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Left Turn with Specified Times",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 195,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Straight Through or Right Turn",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 196,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Straight Through or Left Turn",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 197,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Turns",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 198,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No U-turn",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 199,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Buses Excepted (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 200,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "One Way (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 201,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "One Way (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 202,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Do Not Enter",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 203,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Do Not Enter (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 204,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Do Not Enter/Wrong Way",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 205,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Two-Way Traffic (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 206,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Two-Way Traffic (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 207,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Keep Right (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 208,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Keep Right (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 209,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Through Traffic Keep Right (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 210,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Through Traffic Keep Right (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 211,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Do Not Pass",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 212,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Passing Permited",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 213,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Pass With Care (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 214,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Keep Right Except to Pass",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 215,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Slower Traffic Keep Right",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 216,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Passing Lane 2 km Ahead",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 217,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Yield Centre Lane to Opposing Traffic",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 218,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Stop for School Bus When Signals Flashing",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 219,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Both Directions (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 220,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Left Turn Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 221,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Right Turn Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 222,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Straight Through or Left Turn Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 223,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Straight Through or Right Turn Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 224,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Left or Right Turn Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 225,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "All Movements Permitted",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 226,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Straight Through Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 227,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Left Lane (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 228,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Right Lane (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 229,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Two-Way Left-Turn Lane",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 230,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Two-Way Left-Turn Lane, Centre Lane Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 231,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Bicycle Lane (standard size - overhead)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 232,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Bicycle Lane (standard size - ground-mounted)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 233,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Bicycle Lane (oversize - overhead)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 234,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Bicycle Lane (oversize - ground-mounted)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 235,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane (one vehicle class, overhead, no days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 236,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane (one vehicle class, ground-mounted, no days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 237,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane (multiple vehicle classes, overhead, no days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 238,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane (multiple vehicle classes, ground-mounted, no days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 239,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane (one or multiple vehicle class(es), overhead, days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 240,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane (one or multiple vehicle class(es), ground-mounted, days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 241,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane Begins standard size (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 242,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane Begins and Ends standard size (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 243,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane Begins oversize (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 244,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Reserved Lane Begins and Ends oversize (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 245,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "3 or More Persons (educational)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 246,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 247,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 248,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking (standard size) (with days)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 249,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking (oversize) (with days)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 250,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking (standard size) (with days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 251,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking (oversize) (with days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 252,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking, Snow Route (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 253,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking, Snow Route (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 254,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Parking, Emergency Parking Only",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 255,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Parking Restricted (standard size) (with days, times and duration)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 256,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Parking Restricted (oversize) (with days, times and duration)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 257,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Standing (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 258,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Standing (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 259,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Standing (standard size) (with days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 260,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Standing (oversize) (with days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 261,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No stopping (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 262,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No stopping (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 263,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Stopping (standard size) (with days)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 264,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Stopping (oversize) (with days)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 265,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Stopping (standard size) (with days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 266,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Stopping (oversize) (with days and times)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 267,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Disabled Parking Permit",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 268,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Disabled Standing Exemption",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 269,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Disabled Stopping Exemption",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 270,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Truck Route",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 271,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Movements Permitted (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 272,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Heavy Trucks",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 273,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Heavy Trucks (with time restriction)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 274,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Lane Use Restriction (trucks, overhead)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 275,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Lane Use Restriction (trucks, ground-mounted)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 276,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Lane Use Restriction (length-based, overhead)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 277,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Lane Use Restriction (length-based, ground-mounted)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 278,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Tonnes (standard size) (single gross weight)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 279,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Tonnes (oversize) (single gross weight)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 280,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Tonnes (differentiated by truck type)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 281,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Load Restriction in Effect",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 282,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Class B Road",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 283,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Trucks Enter Inspection Station When Lights Flashing",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 284,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Vehicles Over 5 Tonnes Must Have Valid Transponder (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 285,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Vehicles Over 5 Tonnes Must Have Valid Transponder (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 286,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Dangerous Goods Route",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 423,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Low Bridge Ahead",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 75, class_id: 6, service_day: 0, label_type: 423 },
          { id: 74, class_id: 5, service_day: 0, label_type: 423 },
          { id: 73, class_id: 4, service_day: 0, label_type: 423 },
          { id: 72, class_id: 3, service_day: 0, label_type: 423 },
          { id: 71, class_id: 2, service_day: 0, label_type: 423 },
        ],
      },
      {
        id: 287,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Dangerous Goods Route (educational tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 288,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Dangerous Goods",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 289,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Dangerous Goods Carriers Prohibited (educational tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 290,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "School Bus Loading Zone (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 291,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "School Bus Loading Zone (oversize)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 292,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Tractors",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 293,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Bicycle Route (standard size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 294,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Bicycle Route (reduced size)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 295,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Bicycles",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 296,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Pedestrians or Bicycles",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 297,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Snowmobile Route",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 298,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Snowmobiles",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 299,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Right Turn on Red",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 300,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Left Turn on Red",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 301,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Stop Here on Red Signal",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 302,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Advanced Green When Flashing",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 303,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Left-Turn Signal",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 304,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Construction Zone Begins",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 305,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Construction Zone Ends",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 306,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Yield to Oncoming Traffic",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 307,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Road Closed",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 308,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Community Safety Zone",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 309,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Walk on Left Facing Traffic",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 310,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Fishing from Bridge",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 311,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Vehicles with Lugs Prohibited",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 312,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Littering",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 313,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Maximum Fine for Litttering (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 314,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Littering and Maximum Fine for Littering",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 315,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No In-Line Skating",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 316,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Keep Off Median",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 317,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "No Pedestrians",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 318,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Fasten Seat Belt",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 319,
        rating_range: 0,
        parent: 159,
        enabled: "Y",
        name: "Compulsory (tab sign)",
        range_title: "sign",
        city_id: [],
        service_day: [],
      },
      {
        id: 402,
        rating_range: 0,
        parent: 401,
        enabled: "Y",
        name: "Pothole Paved Surface",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 6, class_id: 6, service_day: 30, label_type: 402 },
          { id: 5, class_id: 5, service_day: 30, label_type: 402 },
          { id: 4, class_id: 4, service_day: 14, label_type: 402 },
          { id: 3, class_id: 3, service_day: 7, label_type: 402 },
          { id: 1, class_id: 2, service_day: 4, label_type: 402 },
        ],
      },
      {
        id: 406,
        rating_range: 0,
        parent: 401,
        enabled: "Y",
        name: "Cracks",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 25, class_id: 6, service_day: 180, label_type: 406 },
          { id: 24, class_id: 5, service_day: 180, label_type: 406 },
          { id: 23, class_id: 4, service_day: 180, label_type: 406 },
          { id: 22, class_id: 3, service_day: 60, label_type: 406 },
          { id: 21, class_id: 2, service_day: 30, label_type: 406 },
        ],
      },
      {
        id: 413,
        rating_range: 0,
        parent: 410,
        enabled: "Y",
        name: "Surface Discontinuities",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 419,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Curve sign with advisory speed tab",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 55, class_id: 6, service_day: 0, label_type: 419 },
          { id: 54, class_id: 5, service_day: 0, label_type: 419 },
          { id: 53, class_id: 4, service_day: 0, label_type: 419 },
          { id: 52, class_id: 3, service_day: 0, label_type: 419 },
          { id: 51, class_id: 2, service_day: 0, label_type: 419 },
        ],
      },
      {
        id: 424,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "One Way",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 80, class_id: 6, service_day: 0, label_type: 424 },
          { id: 79, class_id: 5, service_day: 0, label_type: 424 },
          { id: 78, class_id: 4, service_day: 0, label_type: 424 },
          { id: 77, class_id: 3, service_day: 0, label_type: 424 },
          { id: 76, class_id: 2, service_day: 0, label_type: 424 },
        ],
      },
      {
        id: 428,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Stop Ahead, New",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 100, class_id: 6, service_day: 0, label_type: 428 },
          { id: 99, class_id: 5, service_day: 0, label_type: 428 },
          { id: 98, class_id: 4, service_day: 0, label_type: 428 },
          { id: 97, class_id: 3, service_day: 0, label_type: 428 },
          { id: 96, class_id: 2, service_day: 0, label_type: 428 },
        ],
      },
      {
        id: 432,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Yield",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 120, class_id: 6, service_day: 0, label_type: 432 },
          { id: 119, class_id: 5, service_day: 0, label_type: 432 },
          { id: 118, class_id: 4, service_day: 0, label_type: 432 },
          { id: 117, class_id: 3, service_day: 0, label_type: 432 },
          { id: 116, class_id: 2, service_day: 0, label_type: 432 },
        ],
      },
      {
        id: 495,
        rating_range: 110,
        parent: 374,
        enabled: "Y",
        name: "Speed Signs - Rb-2a X",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 437,
        rating_range: 0,
        parent: 435,
        enabled: "Y",
        name: "Illegible Sign",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 135, class_id: 6, service_day: 30, label_type: 437 },
          { id: 134, class_id: 5, service_day: 30, label_type: 437 },
          { id: 133, class_id: 4, service_day: 30, label_type: 437 },
          { id: 132, class_id: 3, service_day: 21, label_type: 437 },
          { id: 131, class_id: 2, service_day: 14, label_type: 437 },
        ],
      },
      {
        id: 444,
        rating_range: 0,
        parent: 399,
        enabled: "Y",
        name: "Weather",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 448,
        rating_range: 0,
        parent: 445,
        enabled: "Y",
        name: "Vehicle Blocking Road",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 165, class_id: 6, service_day: 0, label_type: 448 },
          { id: 164, class_id: 5, service_day: 0, label_type: 448 },
          { id: 163, class_id: 4, service_day: 0, label_type: 448 },
          { id: 162, class_id: 3, service_day: 0, label_type: 448 },
          { id: 161, class_id: 2, service_day: 0, label_type: 448 },
        ],
      },
      {
        id: 452,
        rating_range: 0,
        parent: 445,
        enabled: "Y",
        name: "Sidewalk Plowing Required",
        range_title: "Depth (CM)",
        city_id: [8],
        service_day: [
          { id: 185, class_id: 6, service_day: 2, label_type: 452 },
          { id: 184, class_id: 5, service_day: 2, label_type: 452 },
          { id: 183, class_id: 4, service_day: 2, label_type: 452 },
          { id: 182, class_id: 3, service_day: 2, label_type: 452 },
          { id: 181, class_id: 2, service_day: 2, label_type: 452 },
        ],
      },
      {
        id: 456,
        rating_range: 0,
        parent: 0,
        enabled: "Y",
        name: "Other",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 465,
        rating_range: 0,
        parent: 458,
        enabled: "Y",
        name: "Concrete Barrier",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 230, class_id: 6, service_day: 330, label_type: 465 },
          { id: 229, class_id: 5, service_day: 330, label_type: 465 },
          { id: 228, class_id: 4, service_day: 330, label_type: 465 },
          { id: 227, class_id: 3, service_day: 330, label_type: 465 },
          { id: 226, class_id: 2, service_day: 330, label_type: 465 },
        ],
      },
      {
        id: 469,
        rating_range: 0,
        parent: 468,
        enabled: "Y",
        name: "Road Accident",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 245, class_id: 6, service_day: 0, label_type: 469 },
          { id: 244, class_id: 5, service_day: 0, label_type: 469 },
          { id: 243, class_id: 4, service_day: 0, label_type: 469 },
          { id: 242, class_id: 3, service_day: 0, label_type: 469 },
          { id: 241, class_id: 2, service_day: 0, label_type: 469 },
        ],
      },
      {
        id: 473,
        rating_range: 0,
        parent: 461,
        enabled: "Y",
        name: "Culvert",
        range_title: "n/a",
        city_id: [1, 2, 3, 4, 5, 6, 7, 8],
        service_day: [
          { id: 205, class_id: 6, service_day: 15, label_type: 473 },
          { id: 204, class_id: 5, service_day: 15, label_type: 473 },
          { id: 203, class_id: 4, service_day: 15, label_type: 473 },
          { id: 202, class_id: 3, service_day: 15, label_type: 473 },
          { id: 201, class_id: 2, service_day: 15, label_type: 473 },
        ],
      },
      {
        id: 482,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Flooding",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 260, class_id: 6, service_day: 3, label_type: 482 },
          { id: 259, class_id: 5, service_day: 3, label_type: 482 },
          { id: 258, class_id: 4, service_day: 3, label_type: 482 },
          { id: 257, class_id: 3, service_day: 3, label_type: 482 },
          { id: 256, class_id: 2, service_day: 3, label_type: 482 },
        ],
      },
      {
        id: 486,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Asphalt Repair",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 280, class_id: 6, service_day: 365, label_type: 486 },
          { id: 279, class_id: 5, service_day: 365, label_type: 486 },
          { id: 278, class_id: 4, service_day: 365, label_type: 486 },
          { id: 277, class_id: 3, service_day: 365, label_type: 486 },
          { id: 276, class_id: 2, service_day: 365, label_type: 486 },
        ],
      },
      {
        id: 489,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Grading Required",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 295, class_id: 6, service_day: 30, label_type: 489 },
          { id: 294, class_id: 5, service_day: 30, label_type: 489 },
          { id: 293, class_id: 4, service_day: 30, label_type: 489 },
          { id: 292, class_id: 3, service_day: 30, label_type: 489 },
          { id: 291, class_id: 2, service_day: 30, label_type: 489 },
        ],
      },
      {
        id: 492,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Graffitti",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 310, class_id: 6, service_day: 30, label_type: 492 },
          { id: 309, class_id: 5, service_day: 30, label_type: 492 },
          { id: 308, class_id: 4, service_day: 30, label_type: 492 },
          { id: 307, class_id: 3, service_day: 30, label_type: 492 },
          { id: 306, class_id: 2, service_day: 30, label_type: 492 },
        ],
      },
      {
        id: 403,
        rating_range: 0,
        parent: 401,
        enabled: "Y",
        name: "Pothole Non-Paved Surface",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 10, class_id: 6, service_day: 30, label_type: 403 },
          { id: 9, class_id: 5, service_day: 30, label_type: 403 },
          { id: 8, class_id: 4, service_day: 14, label_type: 403 },
          { id: 7, class_id: 3, service_day: 7, label_type: 403 },
        ],
      },
      {
        id: 407,
        rating_range: 20,
        parent: 401,
        enabled: "Y",
        name: "Debris",
        range_title: "# of items",
        city_id: [8],
        service_day: [
          { id: 30, class_id: 6, service_day: 0, label_type: 407 },
          { id: 29, class_id: 5, service_day: 0, label_type: 407 },
          { id: 28, class_id: 4, service_day: 0, label_type: 407 },
          { id: 27, class_id: 3, service_day: 0, label_type: 407 },
          { id: 26, class_id: 2, service_day: 0, label_type: 407 },
        ],
      },
      {
        id: 414,
        rating_range: 0,
        parent: 399,
        enabled: "Y",
        name: "Sign",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 420,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Do Not Enter",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 60, class_id: 6, service_day: 0, label_type: 420 },
          { id: 59, class_id: 5, service_day: 0, label_type: 420 },
          { id: 58, class_id: 4, service_day: 0, label_type: 420 },
          { id: 57, class_id: 3, service_day: 0, label_type: 420 },
          { id: 56, class_id: 2, service_day: 0, label_type: 420 },
        ],
      },
      {
        id: 425,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "School Zone Speed Limit",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 85, class_id: 6, service_day: 0, label_type: 425 },
          { id: 84, class_id: 5, service_day: 0, label_type: 425 },
          { id: 83, class_id: 4, service_day: 0, label_type: 425 },
          { id: 82, class_id: 3, service_day: 0, label_type: 425 },
          { id: 81, class_id: 2, service_day: 0, label_type: 425 },
        ],
      },
      {
        id: 429,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Traffic Signal Ahead, New",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 105, class_id: 6, service_day: 0, label_type: 429 },
          { id: 104, class_id: 5, service_day: 0, label_type: 429 },
          { id: 103, class_id: 4, service_day: 0, label_type: 429 },
          { id: 102, class_id: 3, service_day: 0, label_type: 429 },
          { id: 101, class_id: 2, service_day: 0, label_type: 429 },
        ],
      },
      {
        id: 433,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Yield Ahead",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 125, class_id: 6, service_day: 0, label_type: 433 },
          { id: 124, class_id: 5, service_day: 0, label_type: 433 },
          { id: 123, class_id: 4, service_day: 0, label_type: 433 },
          { id: 122, class_id: 3, service_day: 0, label_type: 433 },
          { id: 121, class_id: 2, service_day: 0, label_type: 433 },
        ],
      },
      {
        id: 438,
        rating_range: 0,
        parent: 435,
        enabled: "Y",
        name: "Improperly Oriented Sign",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 140, class_id: 6, service_day: 30, label_type: 438 },
          { id: 139, class_id: 5, service_day: 30, label_type: 438 },
          { id: 138, class_id: 4, service_day: 30, label_type: 438 },
          { id: 137, class_id: 3, service_day: 21, label_type: 438 },
          { id: 136, class_id: 2, service_day: 14, label_type: 438 },
        ],
      },
      {
        id: 445,
        rating_range: 0,
        parent: 444,
        enabled: "Y",
        name: "Snow/Ice Accumulation",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 449,
        rating_range: 0,
        parent: 445,
        enabled: "Y",
        name: "Bicycle Lane Plowing Required",
        range_title: "Depth (CM)",
        city_id: [8],
        service_day: [
          { id: 170, class_id: 6, service_day: 1, label_type: 449 },
          { id: 169, class_id: 5, service_day: 1, label_type: 449 },
          { id: 168, class_id: 4, service_day: 1, label_type: 449 },
          { id: 167, class_id: 3, service_day: 1, label_type: 449 },
          { id: 166, class_id: 2, service_day: 0, label_type: 449 },
        ],
      },
      {
        id: 453,
        rating_range: 0,
        parent: 445,
        enabled: "Y",
        name: "Sidewalk Salt Required",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 190, class_id: 6, service_day: 2, label_type: 453 },
          { id: 189, class_id: 5, service_day: 2, label_type: 453 },
          { id: 188, class_id: 4, service_day: 2, label_type: 453 },
          { id: 187, class_id: 3, service_day: 2, label_type: 453 },
          { id: 186, class_id: 2, service_day: 2, label_type: 453 },
        ],
      },
      {
        id: 458,
        rating_range: 0,
        parent: 456,
        enabled: "Y",
        name: "Barrier",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 466,
        rating_range: 0,
        parent: 458,
        enabled: "Y",
        name: "Fence",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 235, class_id: 6, service_day: 330, label_type: 466 },
          { id: 234, class_id: 5, service_day: 330, label_type: 466 },
          { id: 233, class_id: 4, service_day: 330, label_type: 466 },
          { id: 232, class_id: 3, service_day: 330, label_type: 466 },
          { id: 231, class_id: 2, service_day: 330, label_type: 466 },
        ],
      },
      {
        id: 470,
        rating_range: 0,
        parent: 468,
        enabled: "Y",
        name: "Other (Add Note)",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 474,
        rating_range: 0,
        parent: 461,
        enabled: "Y",
        name: "Curb and Gutter",
        range_title: "n/a",
        city_id: [1, 2, 3, 4, 5, 6, 7, 8],
        service_day: [
          { id: 210, class_id: 6, service_day: 365, label_type: 474 },
          { id: 209, class_id: 5, service_day: 365, label_type: 474 },
          { id: 208, class_id: 4, service_day: 365, label_type: 474 },
          { id: 207, class_id: 3, service_day: 365, label_type: 474 },
          { id: 206, class_id: 2, service_day: 365, label_type: 474 },
        ],
      },
      {
        id: 478,
        rating_range: 0,
        parent: 456,
        enabled: "Y",
        name: "Roads Standard",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 483,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Water Ponding",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 265, class_id: 6, service_day: 330, label_type: 483 },
          { id: 264, class_id: 5, service_day: 330, label_type: 483 },
          { id: 263, class_id: 4, service_day: 330, label_type: 483 },
          { id: 262, class_id: 3, service_day: 330, label_type: 483 },
          { id: 261, class_id: 2, service_day: 330, label_type: 483 },
        ],
      },
      {
        id: 487,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Blvd Repair -  Interlock/Grass",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 285, class_id: 6, service_day: 30, label_type: 487 },
          { id: 284, class_id: 5, service_day: 30, label_type: 487 },
          { id: 283, class_id: 4, service_day: 30, label_type: 487 },
          { id: 282, class_id: 3, service_day: 30, label_type: 487 },
          { id: 281, class_id: 2, service_day: 30, label_type: 487 },
        ],
      },
      {
        id: 490,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Road Closure",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 300, class_id: 6, service_day: 2, label_type: 490 },
          { id: 299, class_id: 5, service_day: 2, label_type: 490 },
          { id: 298, class_id: 4, service_day: 2, label_type: 490 },
          { id: 297, class_id: 3, service_day: 2, label_type: 490 },
          { id: 296, class_id: 2, service_day: 2, label_type: 490 },
        ],
      },
      {
        id: 493,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Blvd Contract Grass Cutting",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 315, class_id: 6, service_day: 30, label_type: 493 },
          { id: 314, class_id: 5, service_day: 30, label_type: 493 },
          { id: 313, class_id: 4, service_day: 30, label_type: 493 },
          { id: 312, class_id: 3, service_day: 30, label_type: 493 },
          { id: 311, class_id: 2, service_day: 30, label_type: 493 },
        ],
      },
      {
        id: 326,
        rating_range: 0,
        parent: 134,
        enabled: "Y",
        name: "Bumps and Sags - Low Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 327,
        rating_range: 0,
        parent: 134,
        enabled: "Y",
        name: "Bumps and Sags - Medium Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 328,
        rating_range: 0,
        parent: 134,
        enabled: "Y",
        name: "Bumps and Sags - High Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 335,
        rating_range: 0,
        parent: 137,
        enabled: "Y",
        name: "Edge Cracking - Low Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 336,
        rating_range: 0,
        parent: 137,
        enabled: "Y",
        name: "Edge Cracking - Medium Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 337,
        rating_range: 0,
        parent: 137,
        enabled: "Y",
        name: "Edge Cracking - High Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 338,
        rating_range: 0,
        parent: 138,
        enabled: "Y",
        name: "Joint Reflection Cracking - Low Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 339,
        rating_range: 0,
        parent: 138,
        enabled: "Y",
        name: "Joint Reflection Cracking - Medium Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 340,
        rating_range: 0,
        parent: 138,
        enabled: "Y",
        name: "Joint Reflection Cracking - High Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 341,
        rating_range: 0,
        parent: 139,
        enabled: "Y",
        name: "Lane/Shoulder Drop Off - Low Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 342,
        rating_range: 0,
        parent: 139,
        enabled: "Y",
        name: "Lane/Shoulder Drop Off - Medium Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 343,
        rating_range: 0,
        parent: 139,
        enabled: "Y",
        name: "Lane/Shoulder Drop Off - High Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 344,
        rating_range: 0,
        parent: 140,
        enabled: "Y",
        name: "Long & Trans Cracking - Low Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 345,
        rating_range: 0,
        parent: 140,
        enabled: "Y",
        name: "Long & Trans Cracking - Medium Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 346,
        rating_range: 0,
        parent: 140,
        enabled: "Y",
        name: "Long & Trans Cracking - High Severity",
        range_title: "ft",
        city_id: [],
        service_day: [],
      },
      {
        id: 131,
        rating_range: 0,
        parent: 128,
        enabled: "Y",
        name: "Alligator Cracking - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 320,
        rating_range: 0,
        parent: 132,
        enabled: "Y",
        name: "Bleeding - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 321,
        rating_range: 0,
        parent: 132,
        enabled: "Y",
        name: "Bleeding - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 322,
        rating_range: 0,
        parent: 132,
        enabled: "Y",
        name: "Bleeding - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 323,
        rating_range: 0,
        parent: 133,
        enabled: "Y",
        name: "Block Cracking - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 324,
        rating_range: 0,
        parent: 133,
        enabled: "Y",
        name: "Block Cracking - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 325,
        rating_range: 0,
        parent: 133,
        enabled: "Y",
        name: "Block Cracking - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 329,
        rating_range: 0,
        parent: 135,
        enabled: "Y",
        name: "Corrugation - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 330,
        rating_range: 0,
        parent: 135,
        enabled: "Y",
        name: "Corrugation - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 331,
        rating_range: 0,
        parent: 135,
        enabled: "Y",
        name: "Corrugation - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 332,
        rating_range: 0,
        parent: 136,
        enabled: "Y",
        name: "Depression - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 333,
        rating_range: 0,
        parent: 136,
        enabled: "Y",
        name: "Depression - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 334,
        rating_range: 0,
        parent: 136,
        enabled: "Y",
        name: "Depression - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 347,
        rating_range: 0,
        parent: 141,
        enabled: "Y",
        name: "Patching & Util Cut Patching - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 348,
        rating_range: 0,
        parent: 141,
        enabled: "Y",
        name: "Patching & Util Cut Patching - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 350,
        rating_range: 0,
        parent: 142,
        enabled: "Y",
        name: "Polished Aggregate - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 351,
        rating_range: 0,
        parent: 142,
        enabled: "Y",
        name: "Polished Aggregate - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 352,
        rating_range: 0,
        parent: 142,
        enabled: "Y",
        name: "Polished Aggregate - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 353,
        rating_range: 0,
        parent: 143,
        enabled: "Y",
        name: "Potholes - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 354,
        rating_range: 0,
        parent: 143,
        enabled: "Y",
        name: "Potholes - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 355,
        rating_range: 0,
        parent: 143,
        enabled: "Y",
        name: "Potholes - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 356,
        rating_range: 0,
        parent: 144,
        enabled: "Y",
        name: "Railroad Crossings - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 357,
        rating_range: 0,
        parent: 144,
        enabled: "Y",
        name: "Railroad Crossings - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 358,
        rating_range: 0,
        parent: 144,
        enabled: "Y",
        name: "Railroad Crossings - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 359,
        rating_range: 0,
        parent: 145,
        enabled: "Y",
        name: "Rutting - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 360,
        rating_range: 0,
        parent: 145,
        enabled: "Y",
        name: "Rutting - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 361,
        rating_range: 0,
        parent: 145,
        enabled: "Y",
        name: "Rutting - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 362,
        rating_range: 0,
        parent: 146,
        enabled: "Y",
        name: "Shoving - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 363,
        rating_range: 0,
        parent: 146,
        enabled: "Y",
        name: "Shoving - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 364,
        rating_range: 0,
        parent: 146,
        enabled: "Y",
        name: "Shoving - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 365,
        rating_range: 0,
        parent: 147,
        enabled: "Y",
        name: "Slippage Cracking - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 366,
        rating_range: 0,
        parent: 147,
        enabled: "Y",
        name: "Slippage Cracking - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 367,
        rating_range: 0,
        parent: 147,
        enabled: "Y",
        name: "Slippage Cracking - High Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 368,
        rating_range: 0,
        parent: 148,
        enabled: "Y",
        name: "Swell - Low Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 369,
        rating_range: 0,
        parent: 148,
        enabled: "Y",
        name: "Swell - Medium Severity",
        range_title: "sqft",
        city_id: [],
        service_day: [],
      },
      {
        id: 381,
        rating_range: 100,
        parent: 374,
        enabled: "Y",
        name: "School Zone Speed Sign -Rb-6a",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 380,
        rating_range: 100,
        parent: 374,
        enabled: "Y",
        name: "School Zone Speed Sign -Rb-6",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 379,
        rating_range: 100,
        parent: 374,
        enabled: "Y",
        name: "Speed Ahead Sign - Rb-5",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 376,
        rating_range: 110,
        parent: 374,
        enabled: "Y",
        name: "Speed Signs - Rb-1a",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 377,
        rating_range: 110,
        parent: 374,
        enabled: "Y",
        name: "Speed Signs - Rb-2",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 378,
        rating_range: 110,
        parent: 374,
        enabled: "Y",
        name: "Speed Signs - Rb-3",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 390,
        rating_range: 110,
        parent: 374,
        enabled: "Y",
        name: "Speed Signs - Rb-1a X",
        range_title: "Speed Limits",
        city_id: [1, 3, 7],
        service_day: [],
      },
      {
        id: 391,
        rating_range: 170,
        parent: 374,
        enabled: "Y",
        name: "Grey County - Road Number Sign",
        range_title: "Road #",
        city_id: [7],
        service_day: [],
      },
      {
        id: 392,
        rating_range: 0,
        parent: 97,
        enabled: "Y",
        name: "Litter Jubilee - Recycling",
        range_title: "level",
        city_id: [3],
        service_day: [],
      },
      {
        id: 404,
        rating_range: 0,
        parent: 401,
        enabled: "Y",
        name: "Pothole Shoulder",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 15, class_id: 6, service_day: 60, label_type: 404 },
          { id: 14, class_id: 5, service_day: 60, label_type: 404 },
          { id: 13, class_id: 4, service_day: 30, label_type: 404 },
          { id: 12, class_id: 3, service_day: 14, label_type: 404 },
          { id: 11, class_id: 2, service_day: 7, label_type: 404 },
        ],
      },
      {
        id: 408,
        rating_range: 20,
        parent: 401,
        enabled: "Y",
        name: "Luminaire",
        range_title: "# of lights",
        city_id: [8],
        service_day: [
          { id: 35, class_id: 6, service_day: 14, label_type: 408 },
          { id: 34, class_id: 5, service_day: 14, label_type: 408 },
          { id: 33, class_id: 4, service_day: 14, label_type: 408 },
          { id: 32, class_id: 3, service_day: 14, label_type: 408 },
          { id: 31, class_id: 2, service_day: 7, label_type: 408 },
        ],
      },
      {
        id: 416,
        rating_range: 0,
        parent: 414,
        enabled: "Y",
        name: "Regulatory/Warning Sign Sec 11",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 421,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Load Restricted Bridge",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 65, class_id: 6, service_day: 0, label_type: 421 },
          { id: 64, class_id: 5, service_day: 0, label_type: 421 },
          { id: 63, class_id: 4, service_day: 0, label_type: 421 },
          { id: 62, class_id: 3, service_day: 0, label_type: 421 },
          { id: 61, class_id: 2, service_day: 0, label_type: 421 },
        ],
      },
      {
        id: 426,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Stop",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 90, class_id: 6, service_day: 0, label_type: 426 },
          { id: 89, class_id: 5, service_day: 0, label_type: 426 },
          { id: 88, class_id: 4, service_day: 0, label_type: 426 },
          { id: 87, class_id: 3, service_day: 0, label_type: 426 },
          { id: 86, class_id: 2, service_day: 0, label_type: 426 },
        ],
      },
      {
        id: 430,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Two-Way Traffic Ahead",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 110, class_id: 6, service_day: 0, label_type: 430 },
          { id: 109, class_id: 5, service_day: 0, label_type: 430 },
          { id: 108, class_id: 4, service_day: 0, label_type: 430 },
          { id: 107, class_id: 3, service_day: 0, label_type: 430 },
          { id: 106, class_id: 2, service_day: 0, label_type: 430 },
        ],
      },
      {
        id: 434,
        rating_range: 0,
        parent: 416,
        enabled: "Y",
        name: "Yield Ahead, New",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 130, class_id: 6, service_day: 0, label_type: 434 },
          { id: 129, class_id: 5, service_day: 0, label_type: 434 },
          { id: 128, class_id: 4, service_day: 0, label_type: 434 },
          { id: 127, class_id: 3, service_day: 0, label_type: 434 },
          { id: 126, class_id: 2, service_day: 0, label_type: 434 },
        ],
      },
      {
        id: 441,
        rating_range: 0,
        parent: 435,
        enabled: "Y",
        name: "Obscured Sign",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 145, class_id: 6, service_day: 30, label_type: 441 },
          { id: 144, class_id: 5, service_day: 30, label_type: 441 },
          { id: 143, class_id: 4, service_day: 30, label_type: 441 },
          { id: 142, class_id: 3, service_day: 21, label_type: 441 },
          { id: 141, class_id: 2, service_day: 14, label_type: 441 },
        ],
      },
      {
        id: 446,
        rating_range: 100,
        parent: 445,
        enabled: "Y",
        name: "Road Plowing Required",
        range_title: "Depth (CM)",
        city_id: [8],
        service_day: [
          { id: 155, class_id: 6, service_day: 1, label_type: 446 },
          { id: 154, class_id: 5, service_day: 1, label_type: 446 },
          { id: 153, class_id: 4, service_day: 0, label_type: 446 },
          { id: 152, class_id: 3, service_day: 0, label_type: 446 },
          { id: 151, class_id: 2, service_day: 0, label_type: 446 },
        ],
      },
      {
        id: 450,
        rating_range: 0,
        parent: 445,
        enabled: "Y",
        name: "Bicycle Lane Salt Required",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 175, class_id: 6, service_day: 1, label_type: 450 },
          { id: 174, class_id: 5, service_day: 1, label_type: 450 },
          { id: 173, class_id: 4, service_day: 1, label_type: 450 },
          { id: 172, class_id: 3, service_day: 1, label_type: 450 },
          { id: 171, class_id: 2, service_day: 0, label_type: 450 },
        ],
      },
      {
        id: 454,
        rating_range: 0,
        parent: 399,
        enabled: "Y",
        name: "Bridge",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 461,
        rating_range: 0,
        parent: 456,
        enabled: "Y",
        name: "Drainage",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 467,
        rating_range: 0,
        parent: 458,
        enabled: "Y",
        name: "Steel Beam Guiderail",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 240, class_id: 6, service_day: 330, label_type: 467 },
          { id: 239, class_id: 5, service_day: 330, label_type: 467 },
          { id: 238, class_id: 4, service_day: 330, label_type: 467 },
          { id: 237, class_id: 3, service_day: 330, label_type: 467 },
          { id: 236, class_id: 2, service_day: 330, label_type: 467 },
        ],
      },
      {
        id: 471,
        rating_range: 0,
        parent: 458,
        enabled: "Y",
        name: "Cable & Post Guidrail",
        range_title: "n/a",
        city_id: [8],
        service_day: [],
      },
      {
        id: 475,
        rating_range: 0,
        parent: 461,
        enabled: "Y",
        name: "Ditch Inlet",
        range_title: "n/a",
        city_id: [1, 2, 3, 4, 5, 6, 7, 8],
        service_day: [
          { id: 215, class_id: 6, service_day: 15, label_type: 475 },
          { id: 214, class_id: 5, service_day: 15, label_type: 475 },
          { id: 213, class_id: 4, service_day: 15, label_type: 475 },
          { id: 212, class_id: 3, service_day: 15, label_type: 475 },
          { id: 211, class_id: 2, service_day: 15, label_type: 475 },
        ],
      },
      {
        id: 480,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Curb/Sidewalk Repair",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 250, class_id: 6, service_day: 365, label_type: 480 },
          { id: 249, class_id: 5, service_day: 365, label_type: 480 },
          { id: 248, class_id: 4, service_day: 365, label_type: 480 },
          { id: 247, class_id: 3, service_day: 365, label_type: 480 },
          { id: 246, class_id: 2, service_day: 365, label_type: 480 },
        ],
      },
      {
        id: 484,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Site Restoration",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 270, class_id: 6, service_day: 365, label_type: 484 },
          { id: 269, class_id: 5, service_day: 365, label_type: 484 },
          { id: 268, class_id: 4, service_day: 365, label_type: 484 },
          { id: 267, class_id: 3, service_day: 365, label_type: 484 },
          { id: 266, class_id: 2, service_day: 365, label_type: 484 },
        ],
      },
      {
        id: 488,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Water Truck",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 290, class_id: 6, service_day: 5, label_type: 488 },
          { id: 289, class_id: 5, service_day: 5, label_type: 488 },
          { id: 288, class_id: 4, service_day: 5, label_type: 488 },
          { id: 287, class_id: 3, service_day: 5, label_type: 488 },
          { id: 286, class_id: 2, service_day: 5, label_type: 488 },
        ],
      },
      {
        id: 491,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "Animal Pickup / Dead Deer",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 305, class_id: 6, service_day: 14, label_type: 491 },
          { id: 304, class_id: 5, service_day: 14, label_type: 491 },
          { id: 303, class_id: 4, service_day: 14, label_type: 491 },
          { id: 302, class_id: 3, service_day: 14, label_type: 491 },
          { id: 301, class_id: 2, service_day: 14, label_type: 491 },
        ],
      },
      {
        id: 494,
        rating_range: 0,
        parent: 478,
        enabled: "Y",
        name: "911 Rural Sign",
        range_title: "n/a",
        city_id: [8],
        service_day: [
          { id: 320, class_id: 6, service_day: 0, label_type: 494 },
          { id: 319, class_id: 5, service_day: 0, label_type: 494 },
          { id: 318, class_id: 4, service_day: 0, label_type: 494 },
          { id: 317, class_id: 3, service_day: 0, label_type: 494 },
          { id: 316, class_id: 2, service_day: 0, label_type: 494 },
        ],
      },
    ],
  },
};
