import React, { useState, useEffect, useRef, useContext } from "react";
import { loadModules, setDefaultOptions } from "esri-loader";
import { IconButton, makeStyles } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DateUtils from "../../../utils/dateUtils/DateUtils";
import useConfirm from "../../../irisUI/confirm/hoc/useConfirm";
import { AuthContext } from "../../AuthContext";
import { togglePatrolRequest } from "../../../utils/api/patrolAPI";

import { useHistory } from "react-router-dom";
import { CURRENT_PATROLER } from "../../../utils/env/localStorageKeys";
import WeatherForcecastView from "../weatherForcecast/WeatherForcecastView";
import { RoadConditionSelection } from "pages/manualPatrol/ManualPatrolForm";
import Auth from "@aws-amplify/auth";
import useAlert from "irisUI/alert/hoc/useAlert";
import { sendRoadCondition } from "utils/api/patrolAPI";

import { MDSSContext } from "context/MDSSContext";
import RoadAlerts from "../roadAlerts/RoadAlerts";

const useStyle = makeStyles(() => ({
  uiToggleBtn: {
    backgroundColor: "#fff",
  },
  actionButtonDiv: {
    transition: "all 0.3s, opacity 0.2s 0.1s",
    width: 130,
    overflow: "hidden",
    opacity: 1,
    position: "absolute",
    backgroundColor: "#fff",
    left: 16,
    top: 60,
  },

  hideActionButtonDiv: {
    left: -136,
  },

  legendDiv: {
    position: "absolute",
    left: 0,
    bottom: 0,
    transition: "all 0.3s, opacity 0.2s 0.1s",
    width: 300,
    overflow: "hidden",
    backgroundColor: "#fff",
    opacity: 1,
  },

  hideLegend: {
    left: "-300px",
  },

  weatherConditionDiv: {
    width: 260,
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: "0.3em",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    overflowX: "hidden",
    transition: "all 0.3s, opacity 0.2s 0.1s",
  },
  hideRight: {
    right: "-300px",
  },
}));

export default function MapWithRoutes(props) {
  const {
    onCameraIconClick: handleCameraIconClick,
    currentLocation,
    locations,
  } = props;

  //when winter patrol, show mdss when the component mounted
  const { patrolType, handleShowRoadCondition, handleShowRoadAlert } =
    useContext(MDSSContext);

  useEffect(() => {
    if (patrolType === "winter") {
      handleShowRoadCondition(true);
      handleShowRoadAlert(true);
    }
  }, []);

  // styles
  const {
    uiToggleBtn,
    actionButtonDiv,
    hideActionButtonDiv,
    legendDiv,
    weatherConditionDiv,
    hideLegend,
    hideRight,
  } = useStyle();
  const { contextToken, contextUsername, setContextUsername, deviceInfo } =
    useContext(AuthContext);

  const history = useHistory();

  const { showAlert } = useAlert();

  /*================================================== states START ================================================== */
  const [mapView, setMapView] = useState(null);

  const [hideActionBtns, setHideActionBtns] = useState(true);

  const [popupTemplate] = useState({
    // autocasts as new PopupTemplate()
    title: "{FULL_NAME}",

    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "FID",
            label: "Field id",
            places: 2,
          },
          {
            fieldName: "RD_CLAS_CO",
            label: "Class",
          },
          {
            fieldName: "FULL_NAME",
            label: "Full Name",
          },
          {
            fieldName: "OWNERSHIP",
            label: "Owenership",
          },
          {
            fieldName: "BLOCK",
            label: "Block",
          },
          {
            fieldName: "PATROL_REG",
            label: "Patrol Reg",
          },
          {
            fieldName: "RECENT_PAT",
            label: "Recent Patrol",
          },
          {
            fieldName: "NEXT_PAT00",
            label: "Next Patrol",
          },
        ],
      },
    ],
  });

  const [roadCondition, setRoadCondition] = useState("");

  /*==================================================  states END  ================================================== */

  /*================================================== references START ================================================== */
  /** dom element holds map ui */
  const mapRef = useRef();

  /** a class defines various graphics (point, line, polygon) */
  const GraphicRef = useRef();

  const pathRef = useRef(null);

  const locationMarkerRef = useRef(null);

  // const addressRef = useRef();

  const weatherForcecastRef = useRef();
  const mapLegendRef = useRef();

  const uiToggleBtnRef = useRef();

  /*==================================================  references END  ================================================== */

  const { isConfirmed } = useConfirm();
  // on mounted, set up the map and map view
  useEffect(() => {
    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/Graphic",
      "esri/widgets/Legend",
      "esri/widgets/Expand",
      "esri/widgets/BasemapGallery",
    ])
      .then(
        async ([
          Map,
          MapView,
          FeatureLayer,
          Graphic,
          Legend,
          Expand,
          BasemapGallery,
        ]) => {
          GraphicRef.current = Graphic;
          const layer = new FeatureLayer({
            url: "https://services8.arcgis.com/JOTTuViCh6VvErd7/arcgis/rest/services/Final_GIS_Segments_Vaughan/FeatureServer",
            outFields: ["*"],
            popupTemplate: popupTemplate,
          });

          const map = new Map({
            basemap: "gray",
            layers: [layer],
          });

          const mapView = new MapView({
            map: map,
            container: mapRef.current,
            constraints: {
              maxScale: 2500,
            },
            // hide zoom widget
            // https://developers.arcgis.com/javascript/latest/sample-code/view-disable-navigation/
            ui: {
              components: ["attribution"],
            },
          });

          // Set the view extent to the feature layer's extent after the layer is loaded
          layer.load().then(function (loadedLayer) {
            mapView.extent = loadedLayer.fullExtent;
          });

          /*================================================== show legend START ================================================== */
          const legend = new Legend({
            view: mapView,
            container: mapLegendRef.current,
            layerInfos: [
              {
                layer: layer,
              },
            ],
          });
          mapView.ui.add(legend, "bottom-left");
          mapView.ui.add(uiToggleBtnRef.current, "top-left");
          // mapView.ui.add(document.getElementById("actionBtnDiv"), "top-left");
          // mapView.ui.add(document.getElementById("stopBtn"), "top-left");
          // mapView.ui.add(document.getElementById("stopBtn"), "top-left");
          // mapView.ui.add(addressRef.current, "top-right");
          mapView.ui.add(weatherForcecastRef.current, "bottom-right");
          /*==================================================  show legend END  ================================================== */

          setMapView(mapView);

          // Create a BasemapGallery widget instance and set
          // its container to a div element

          const basemapGallery = new BasemapGallery({
            view: mapView,
            container: document.createElement("div"),
          });

          // Create an Expand instance and set the content
          // property to the DOM node of the basemap gallery widget
          // Use an Esri icon font to represent the content inside
          // of the Expand widget

          const bgExpand = new Expand({
            view: mapView,
            content: basemapGallery,
          });

          // close the expand whenever a basemap is selected
          basemapGallery.watch("activeBasemap", () => {
            const mobileSize =
              mapView.heightBreakpoint === "xsmall" ||
              mapView.widthBreakpoint === "xsmall";

            if (mobileSize) {
              bgExpand.collapse();
            }
          });

          // Add the expand instance to the ui

          mapView.ui.add(bgExpand, "top-right");
        }
      )
      .catch((error) => {
        alert("Error in loading arcgis modules");
        console.log("Error in loading arcgis modules", error);
      });
  }, [popupTemplate]);

  useEffect(() => {
    const { current: Graphic } = GraphicRef;

    if (Graphic && mapView) {
      // // GET ROUTES

      // debugger;
      const routes = locations.map((point) => {
        const [lat, lng] = point;
        return [lng, lat];
      });

      // console.log(`routes`, routes);
      // GET ROUTES END
      const polyline = {
        type: "polyline",
        paths: routes,
      };

      const lineSymbol = {
        // color: [226, 119, 40],
        type: "simple-line",
        color: [0, 255, 239],
        // color: [226, 119, 40],
        width: 4,
      };

      const polylineGraphic = new Graphic({
        geometry: polyline,
        symbol: lineSymbol,
        attributes: {
          date: DateUtils.getyyyyMMddhhmmss(),
        },
        popupTemplate: {
          title: "some info could be display here",
          content: `Patroller: <b>${contextUsername}</b>, on <b>{date}</b>`,
        },
      });

      // remove previous path
      mapView.graphics.remove(pathRef.current);

      // add new path
      mapView.graphics.add(polylineGraphic);

      // reset update path reference
      pathRef.current = polylineGraphic;
    }
  }, [mapView, locations, contextUsername]);

  // center map to current location
  useEffect(() => {
    const { current: Graphic } = GraphicRef;
    const { lat, lng } = currentLocation;
    if (!lat || !lng) return;
    if (mapView && Graphic) {
      const { lat, lng } = currentLocation;
      mapView.center = [lng, lat];
      // mapView.zoom = 16;

      // if (addressRef.current) {
      //   addressRef.current.innerText = lat + ", " + lng;
      // }

      const point = {
        //Create a point
        type: "point",
        longitude: lng,
        latitude: lat,
      };

      const pointGraphic = new Graphic({
        geometry: point,
        // symbol: simpleMarkerSymbol,
        symbol: {
          type: "text", // autocasts as new TextSymbol()
          color: "#7A003C",
          text: "\ue61d", // esri-icon-map-pin
          font: {
            // autocasts as new Font()
            // size: 28,
            size: 36, // make this bigger, targeted on ipad
            family: "CalciteWebCoreIcons",
          },
        },
      });

      mapView.graphics.remove(locationMarkerRef.current);
      mapView.graphics.add(pointGraphic);
      locationMarkerRef.current = pointGraphic;
    }
  }, [currentLocation, mapView]);

  useEffect(() => {
    const currentPatroller = JSON.parse(localStorage.getItem(CURRENT_PATROLER));

    if (currentPatroller && currentPatroller.road_condition) {
      const { road_condition } = currentPatroller;
      if (road_condition) {
        setRoadCondition(road_condition);
      }
    }
  }, []);

  const handleFinishPatrol = async () => {
    const localStorageUser = JSON.parse(localStorage.getItem(CURRENT_PATROLER));

    const params = JSON.parse(JSON.stringify(localStorageUser));
    try {
      params.was_in_patrol = false;
      const response = await togglePatrolRequest(contextToken, params, false);

      if (response?.success) {
        localStorage.setItem(CURRENT_PATROLER, null);
        // history.push("patrol-form");
        setContextUsername();
      }
      await Auth.signOut({ global: true });
    } catch (error) {
      let { message = "" } = error;
      await showAlert(message);

      if (message.includes("non-exist patrol")) {
        try {
          const toggleStartPatrolResponse = await togglePatrolRequest(
            contextToken,
            params,
            true
          );
          if (toggleStartPatrolResponse?.success) {
            showAlert(
              "Deivce has been toggled, you should be able to loggout now"
            );
          }
        } catch (e) {
          alert("Error in start deivce: " + e.message);
        }
      }
    } finally {
      // localStorage.setItem(CURRENT_PATROLER, null);
      // // history.push("patrol-form");
      // setContextUsername();
    }
  };

  return (
    <>
      <div
        ref={mapRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      ></div>
      {/* <span
        ref={addressRef}
        style={{
          fontSize: 20,
          padding: "0.375em 0.5em",
          fontWeight: "bold",
          backgroundColor: "#fff",
        }}
      ></span> */}

      <div ref={uiToggleBtnRef} className={uiToggleBtn}>
        <IconButton
          style={{ height: "100%", borderRadius: 0 }}
          size="small"
          onClick={() => setHideActionBtns((prev) => !prev)}
        >
          {hideActionBtns ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <WeatherForcecastView page="map" />
      {/* <RoadAlerts /> */}
      <div
        className={`${actionButtonDiv} ${
          hideActionBtns ? hideActionButtonDiv : ""
        }`}
      >
        <IconButton
          color="primary"
          onClick={handleCameraIconClick}
          style={{ display: "block" }}
        >
          <PhotoCameraIcon style={{ width: 100, height: 100 }} />
        </IconButton>

        <IconButton
          color="secondary"
          onClick={async () => {
            const confirmed = await isConfirmed(
              "Have you finished patroling? By confirming, your path would be cleared and you would be logged out"
            );

            if (confirmed) {
              handleFinishPatrol();
            }
          }}
          style={{ display: "block" }}
        >
          <CheckCircleOutlineIcon style={{ width: 100, height: 100 }} />
        </IconButton>
      </div>
      <div
        ref={mapLegendRef}
        className={`${legendDiv} ${hideActionBtns ? hideLegend : ""}`}
      />
      <div ref={weatherForcecastRef}>
        <div
          className={`${weatherConditionDiv} ${
            hideActionBtns ? hideRight : ""
          }`}
        >
          <RoadAlerts />
          <div style={{ width: "100%", backgroundColor: "#fff" }}>
            <RoadConditionSelection
              value={roadCondition}
              onChange={async (selection) => {
                const confirm = await isConfirmed(
                  `Road condition: "${selection.name}" updated.`
                );
                if (confirm) {
                  setRoadCondition(selection);
                  try {
                    const response = await sendRoadCondition(
                      contextToken,
                      deviceInfo.device_sn,
                      selection.name
                    );
                    if (response) {
                      const { success, message } = response;
                      process.env.NODE_ENV === "development" &&
                        console.log("success, message: ", success, message);
                      if (success) {
                        props.onUploadRoadConditionSucceed();
                      }
                    }
                  } catch (error) {
                    await showAlert(error.message);
                  }
                } else {
                  setRoadCondition({ ...roadCondition });
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
