import React, { createContext, useState } from "react";

export const ConfirmContext = createContext([
  {
    /** confirmation message */
    message: "",
    /** if true, open the custom confirmation modal, false otherwise */
    isOpen: false,
    proceed: () => {},
    cancel: () => {},
  },
  () => {},
]);

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    message: "",
    isOpen: false,
    proceed: null,
    cancel: null,
  });

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;
