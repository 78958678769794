import { DEMO_LOOKUP_RESULT } from "demoAPIData/demoLookupResult";
import { CREATE_WORK_ORDER_URL } from "utils/env/urls";
import DateUtils from "../dateUtils/DateUtils";

import {
  UPLOAD_IMAGE_URL,
  UPDATE_DATA_POINT_URL,
  UPLOAD_LABEL_URL,
  LOOK_UP_URL,
  GET_ROUTE_URL,
} from "./apiURLS";

// device_sn: 4
// latitude: 45.49767748
// longitude: -73.65223727

const getHardCodedImageMetadata = (deviceId, lat, lng, imageFile) => {
  const ts = DateUtils.getDatabaseTimeStamp();
  return {
    device_sn: deviceId,
    latitude: lat,
    longitude: lng,
    "accelerometer_info[0]acc_x": 0.0,
    "accelerometer_info[0]acc_y": 0.0,
    "accelerometer_info[0]acc_z": 0.0,
    "accelerometer_info[0]linear_acc_z": 0.0,
    "accelerometer_info[0]linear_acc_y": 0.0,
    "accelerometer_info[0]linear_acc_x": 0.0,
    app_version: 1,
    speed: 0.0,
    heading: 0,
    "accelerometer_info[0]timestamp": ts,
    create_time: ts,
    image: imageFile,
  };
};

export const uploadImageRequest = async (
  token,
  deviceId,
  lat,
  lng,
  imageFile
) => {
  return new Promise(async (resolve, reject) => {
    resolve({
      success: true,
      result: { dataId: Math.round(Math.random()) + 1 },
      message: "okay",
    });
  });
};

export const createBoundingBoxesRequest = async (token, boxes, dataPointId) => {
  const boundingBoxObject = getBoudingBoxesUploadData([boxes], dataPointId);
  return new Promise(async (resolve, reject) => {
    const responseJson = { result: { label_id: Math.round() } };
    responseJson.label_type = boxes.id;
    responseJson.w_o_type = boxes.name;
    resolve(responseJson);
  });
};

export const updateDataPointRequest = async (
  token,
  dataPointId,
  comment = ""
) => {
  return new Promise(async (resolve, reject) => {
    resolve({});
  });
};

export const getCurrentRoute = async (token, device_sn, isAll) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };

  let url = GET_ROUTE_URL + device_sn;
  if (isAll === true || isAll === "true") {
    url += "&all=true";
  }
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

export const lookup = async (token) => {
  return new Promise(async (resolve, reject) => {
    resolve(DEMO_LOOKUP_RESULT);
  });
};

/**
 * @summary converted image metata, in json formate, to form data format
 *
 * @param {Object} params json object represents metadata for image
 * @returns form data generated by iamge metadata
 */
const getFormData = (params) => {
  const formData = new FormData();
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const element = params[key];
      formData.append(key, element);
    }
  }
  return formData;
};

const getBoudingBoxesUploadData = (boudingBoxes, dataPointId) => {
  let newBoundingBoxeData = {};
  for (let index = 0; index < boudingBoxes.length; index++) {
    const box = boudingBoxes[index];
    // new payload for 4-edge-polygon bounding box
    const {
      topLeft = [0, 0],
      topRight = [0, 0],
      bottomRight = [0, 0],
      bottomLeft = [0, 0],
    } = box;
    newBoundingBoxeData[`labels[${index}]bbox`] = JSON.stringify({
      bounding_box: [
        Math.floor(topLeft[0]),
        Math.floor(topLeft[1]),
        Math.floor(topRight[0]),
        Math.floor(topRight[1]),
        Math.floor(bottomRight[0]),
        Math.floor(bottomRight[1]),
        Math.floor(bottomLeft[0]),
        Math.floor(bottomLeft[1]),
      ],
    });
    newBoundingBoxeData[`labels[${index}]label_type`] = box.id;
    newBoundingBoxeData[`labels[${index}]rating`] = box.rating || 0;

    if (index === boudingBoxes.length - 1) {
      newBoundingBoxeData.datapoint = dataPointId;
    }
  }
  return newBoundingBoxeData;
};

export const createWorkOrder = async (params) => {
  return new Promise(async (resolve, reject) => {
    resolve({});
  });
};
