/*******************************************
 * make a marker from graphic
 *
 * 1. make a point object, with lat, lng
 * 2. make a graphic object from the point
 *
 * if want a pop up with dynamic values for the graphic,
 * please set "attributes" for the graphic object
 * and then defined it "popupTemplate" attribute
 *
 ******************************************/

/*******************************************
 * maek a graphic draggable
 *
 * set up callback function for "drag" event on the mapView object
 *
 ******************************************/

import { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { loadModules, setDefaultOptions } from "esri-loader";
setDefaultOptions({
  version: "4.21",
  css: true,
});

const TEXT_SYMBOL = {
  type: "text", // autocasts as new TextSymbol()
  color: "#7A003C",
  text: "\ue61d", // esri-icon-map-pin
  font: {
    // autocasts as new Font()
    // size: 28,
    size: 36, // make this bigger, targeted on ipad
    family: "CalciteWebCoreIcons",
  },
};

const MapWithDraggableMarker = forwardRef((props, ref) => {
  // props
  const { onLocationUpdate: handleLocationUpdate } = props;
  const { center } = props;

  // refs
  const addressRef = useRef(null);
  const locationRef = useRef([center?.lng, center?.lat]);

  const mapViewRef = useRef();

  useImperativeHandle(
    ref,
    () => {
      return {
        takeScreenshot: async () => {
          const mapView = mapViewRef.current;
          let screenshot;
          if (mapView) {
            try {
              screenshot = await mapView.takeScreenshot({ format: "jpg" });
            } catch (error) {
              console.log(`Error in taking screenshot on map view`, error);
            }
          }

          return screenshot;
        },
      };
    },
    []
  );

  useEffect(() => {
    /**
     *
     * @param {*} GraphicClass
     * @param {{lattidue, longitude}} point
     */
    function getCongiguredGraphic(GraphicClass, point) {
      return new GraphicClass({
        geometry: point,
        symbol: TEXT_SYMBOL,
        attributes: {
          latitude: point.latitude,
          longitude: point.longitude,
        },
        popupTemplate: {
          title: "location",
          content: [
            {
              type: "fields", // Autocasts as new FieldsContent()
              // Autocasts as new FieldInfo[]
              fieldInfos: [
                {
                  fieldName: "latitude",
                  format: {
                    places: 7,
                    digitSeparator: true,
                  },
                },
                {
                  fieldName: "longitude",
                  format: {
                    places: 7,
                    digitSeparator: true,
                  },
                },
              ],
            },
          ],
        },
      });
    }

    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/geometry/Point",
      "esri/core/watchUtils",
    ])
      .then(async ([Map, MapView, Graphic, Point, watchUtils]) => {
        // Create a symbol for drawing the point

        const centerLngLat = locationRef.current;
        const point = new Point(locationRef.current);

        // Create a symbol for drawing the point
        const pointGraphic = getCongiguredGraphic(Graphic, point);

        const map = new Map({
          basemap: "topo",
        });

        const mapView = new MapView({
          map: map,
          // center: [-118.805, 34.027], // Longitude, latitude
          center: centerLngLat,
          zoom: 17, // Zoom level
          container: "viewDiv", // Div element
          ui: {
            components: ["attribution"],
          },
        });

        mapViewRef.current = mapView;

        let draggingGraphic, tempGraphic;

        // make graphic (with marker) draggable
        mapView.on("drag", async function (evt) {
          // if this is the starting of the drag, do a hitTest
          if (evt.action === "start") {
            mapView.hitTest(evt).then((resp) => {
              if (
                resp.results[0]?.graphic &&
                resp.results[0]?.graphic.geometry.type === "point"
              ) {
                evt.stopPropagation();
                // if the hitTest returns a point graphic, set dragginGraphic
                draggingGraphic = resp.results[0].graphic;
              }
            });
          } else if (evt.action === "update") {
            // on drag update events, only continue if a draggingGraphic is set
            if (draggingGraphic) {
              mapView.popup.close();
              evt.stopPropagation();
              // if there is a tempGraphic, remove it
              if (tempGraphic) {
                mapView.graphics.remove(tempGraphic);
              } else {
                // if there is no tempGraphic, this is the first update event, so remove original graphic
                mapView.graphics.remove(draggingGraphic);
              }
              // create new temp graphic and add it
              tempGraphic = draggingGraphic.clone();
              tempGraphic.geometry = mapView.toMap(evt);
              mapView.graphics.add(tempGraphic);
            }
          } else if (evt.action === "end") {
            // on drag end, continue only if there is a draggingGraphic
            if (draggingGraphic) {
              evt.stopPropagation();
              // rm temp
              if (tempGraphic) mapView.graphics.remove(tempGraphic);
              // create new graphic based on original dragging graphic

              let newGraphic = draggingGraphic.clone();

              newGraphic.geometry = tempGraphic.geometry.clone();

              const {
                geometry: { latitude, longitude },
              } = newGraphic;

              locationRef.current = [longitude, latitude];
              /**
               * configured graphic with updated popup template
               */
              const configuredGraphic = getCongiguredGraphic(
                Graphic,
                tempGraphic.geometry
              );

              // setRefinedLocation({ lat: latitude, lng: longitude });

              // add replacement graphic
              mapView.graphics.add(configuredGraphic);

              if (typeof handleLocationUpdate === "function") {
                handleLocationUpdate({ lat: latitude, lng: longitude });
              }

              if (addressRef.current) {
                addressRef.current.innerText = latitude + ", " + longitude;
              }

              mapView.goTo({
                center: [longitude, latitude],
              });

              draggingGraphic = null;
              tempGraphic = null;
            }
          }
        });

        mapView.graphics.add(pointGraphic);
        // setTimeout(() => {
        //   mapView.popup.open({ features: [pointGraphic] });
        // }, 1000);

        mapView.ui.add(document.getElementById("addressSpan"), "top-left");

        if (addressRef.current && locationRef.current) {
          const [lng, lat] = locationRef.current;
          addressRef.current.innerText = lat + ", " + lng;
        }
        // setMap(map);
        // setMapView(mapView);

        watchUtils.whenTrue(mapView, "stationary", () => {
          if (locationRef.current) {
            const center = locationRef.current;

            // when the map view was dragged, or zoom, the center of the updated map view
            mapView.goTo(center);
          }
        });
      })
      .catch((error) => {
        alert("Error in loading arcgis modules");
        console.log("Error in loading arcgis modules", error);
      });
  }, [handleLocationUpdate]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        id="viewDiv"
        style={{ width: "100%", height: "100%", position: "relative" }}
      ></div>
      <span
        ref={addressRef}
        id="addressSpan"
        style={{
          backgroundColor: "#fff",
          color: "#000",
          fontSize: "1rem",
          padding: "0.375em 0.5em",
        }}
      >
        address
      </span>
    </div>
  );
});

export default MapWithDraggableMarker;
