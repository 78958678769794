const WEATHER_DATA_REQUEST_RESULT_TEMPLATE = {
  coord: { lon: 0, lat: 0 },
  weather: [
    { id: 804, main: "Clouds", description: "overcast clouds", icon: "04d" },
  ],
  base: "stations",
  main: {
    temp: 3.07,
    feels_like: 1.04,
    temp_min: 2.32,
    temp_max: 4.15,
    pressure: 1024,
    humidity: 82,
    sea_level: 1024,
    grnd_level: 997,
  },
  visibility: 10000,
  wind: { speed: 2.07, deg: 184, gust: 3.88 },
  clouds: { all: 99 },
  dt: 1647275829,
  sys: {
    type: 2,
    id: 0,
    country: "CA",
    sunrise: 1647257526,
    sunset: 1647300143,
  },
  timezone: -14400,
  id: 1,
  name: "Demo City",
  cod: 200,
};

const WEAHTER_INFO = {
  CLEAR_SKY: { title: "clear sky", dayIcon: "01d", nightIcon: "01n" },
  FEW_CLOUDS: { title: "few clouds", dayIcon: "02d", nightIcon: "02n" },
  SCATTERED_CLOUDS: {
    title: "scattered clouds",
    dayIcon: "03d",
    nightIcon: "03n",
  },
  BROKEN_CLOUDS: {
    title: "broken clouds",
    dayIcon: "04d",
    nightIcon: "04n",
  },
  SHOWER_RAIN: {
    title: "shower rain",
    dayIcon: "09d",
    nightIcon: "09n",
  },
  RAIN: { title: "rain", dayIcon: "10d", nightIcon: "10n" },
  THUNDERSTORM: {
    title: "thunderstorm",
    dayIcon: "11d",
    nightIcon: "11n",
  },
  SNOW: { title: "snow", dayIcon: "13d", nightIcon: "13n" },
  MIST: { title: "mist", dayIcon: "50d", nightIcon: "50n" },
};

const getRandomWeahterInfo = () => {
  const template = {
    id: 804,
    main: "Clouds",
    description: "overcast clouds",
    icon: "04d",
  };

  const keys = Object.keys(WEAHTER_INFO);
  const index = Math.round(Math.random() * (keys.length - 1));

  const randomWeather = WEAHTER_INFO[keys[index]];

  const { title, dayIcon, nightIcon } = randomWeather;
  return {
    ...template,
    main: title,
    description: title,
    icon: Math.random() > 0.5 ? dayIcon : nightIcon,
  };
};

const getRandomTemp = () => {
  const template = {
    temp: 3.07,
    feels_like: 1.04,
    temp_min: 2.32,
    temp_max: 4.15,
    pressure: 1024,
    humidity: 82,
    sea_level: 1024,
    grnd_level: 997,
  };

  const randomTemperature = (Math.random() - Math.random()) * 20; // + or - 20

  const tempMin = randomTemperature - Math.random() * 5;
  const tempMax = randomTemperature + Math.random() * 5;
  const humidity = Math.random() * 100;

  return {
    ...template,
    temp_max: tempMax,
    temp_min: tempMin,
    humidity: humidity,
  };
};

export const getMockedWeatherData = () => {
  const mockWeahter = getRandomWeahterInfo();
  const mockTemperature = getRandomTemp();

  return {
    ...WEATHER_DATA_REQUEST_RESULT_TEMPLATE,
    weather: [mockWeahter],
    main: mockTemperature,
  };
};
