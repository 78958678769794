import { useEffect, useState, useContext } from "react";
import useGeoLocation from "../../customHooks/useGeoLocation";
import MuiSnackbar from "../../irisUI/Snackbar/Snackbar";
import { getCurrentRoute } from "../../utils/api/uploadImageAPI";
import { CURRENT_PATROLER } from "../../utils/env/localStorageKeys";
import { AuthContext } from "../AuthContext";
import MapWithRoutes from "../maps/mapWithRoute/MapWithRoutes";
import UploadDataPointModal from "./UploadDataPointModal";

const defaultSnackbarProps = {
  open: false,
  message: "default message",
  severity: "success", // ["error", "info", "warning", "success"]
  position: "top-right", // ["top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"]
};

const ROUTE_UPDATE_INTERVAL = 30 * 1000;
export default function ManualPatrol(props) {
  const [openModal, setOpenModal] = useState(false);

  const {
    contextToken: token,
    deviceInfo: { device_sn },
  } = useContext(AuthContext);
  const [pathSnackbarInfo, setPathSnackbarInfo] = useState({
    ...defaultSnackbarProps,
  });
  const [uploadSnackbarInfo, setUploadSnackbarInfo] = useState({
    ...defaultSnackbarProps,
  });
  const [uploadRoadConditionSnackbarInfo, setUploadRoadConditionSnackbarInfo] =
    useState({
      ...defaultSnackbarProps,
      position: "top-center",
    });

  const {
    loading,
    error: locationError,
    data: { latitude, longitude },
  } = useGeoLocation();
  const [imageLocation, setImageLocation] = useState(null);

  const [currentLocation, setCurrentLocation] = useState({
    lat: latitude || null,
    lng: longitude || null,
  });

  const [locations, setLocations] = useState([]);

  const toggleModal = () => {
    if (!openModal) {
      setOpenModal(true);

      setImageLocation({ lat: latitude, lng: longitude });
    }
  };

  const handleUploadDataPointModalClose = () => {
    setOpenModal(false);
    setImageLocation(false);
  };

  const closeSnackbar = () => {
    setPathSnackbarInfo({ ...defaultSnackbarProps });
  };

  const closeUploadSnackbar = () => {
    setUploadSnackbarInfo({ ...defaultSnackbarProps });
  };

  const closeUploadRoadConditionSnackbar = () => {
    setUploadRoadConditionSnackbarInfo({
      ...defaultSnackbarProps,
      position: "top-center",
    });
  };

  useEffect(() => {
    if (locationError) {
      // alert("Error in watching location: " + JSON.stringify(locationError));
      console.log(`"error in watching location"`, locationError);
    }
  }, [locationError]);

  // useEffect(() => {
  //   const getRoutes = async (isAll = false) => {
  //     try {
  //       const response = await getCurrentRoute(token, device_sn, isAll);

  //       let locationHistory = [];
  //       const result0 = response?.result;
  //       const result1 = response?.result?.location_history;

  //       if (Array.isArray(result0)) {
  //         locationHistory = result0;
  //       } else if (Array.isArray(result1)) {
  //         locationHistory = result1;
  //       }

  //       // console.log(`locationHistory`, locationHistory);
  //       setPathSnackbarInfo({
  //         ...defaultSnackbarProps,
  //         open: true,
  //         message: "Routes are updated",
  //       });

  //       // debugger;
  //       // const temp = locations.concat(locationHistory);
  //       // const tt = temp.filter((ele) => ele.length >= 2);

  //       // console.log(`locations, temp, tt`, locations, temp, tt);
  //       // setLocations(tt);

  //       setLocations((prev) => {
  //         const temp = prev.concat(locationHistory);
  //         const filtered = temp.filter((ele) => ele.length >= 2);
  //         return filtered;
  //       });
  //     } catch (error) {
  //       alert("Error in getting routes" + error.message);
  //       console.log(`Error in getting routes`, error.message);
  //       // error goes here
  //     }
  //   };

  //   const patroller = JSON.parse(localStorage.getItem(CURRENT_PATROLER));
  //   const wasInPatrol = patroller.was_in_patrol;

  //   debugger;
  //   getRoutes(wasInPatrol);
  //   let interval = setInterval(() => {
  //     getRoutes();
  //   }, ROUTE_UPDATE_INTERVAL);

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //       interval = null;
  //     }
  //   };
  // }, [device_sn, token]);

  useEffect(() => {
    process.env.NODE_ENV === "development" && console.log(`setting:`);
    setCurrentLocation({ lat: latitude, lng: longitude });
    if (latitude && longitude) {
      setLocations((prev) => {
        const a = [...prev, [latitude, longitude]];
        return a;
      });
    }
  }, [latitude, longitude]);
  return (
    <>
      <MapWithRoutes
        onCameraIconClick={toggleModal}
        currentLocation={currentLocation}
        locations={locations}
        onUploadRoadConditionSucceed={() =>
          setUploadRoadConditionSnackbarInfo({
            open: true,
            severity: "success",
            position: "top-center",
            message: "Upload success",
          })
        }
        onUploadRoadConditionFailed={() => {
          setUploadRoadConditionSnackbarInfo({
            open: true,
            severity: "error",
            position: "top-center",
            message: "Upload failed",
          });
        }}
      />
      <UploadDataPointModal
        position={imageLocation}
        open={openModal}
        onClose={handleUploadDataPointModalClose}
        onUploadSuccess={() =>
          setUploadSnackbarInfo({
            open: true,
            severity: "success",
            position: "top-center",
            message: "Upload success",
          })
        }
        onUploadFailed={() => {
          setUploadSnackbarInfo({
            open: true,
            severity: "error",
            position: "top-center",
            message: "Upload failed",
          });
        }}
      />
      {/* snack bar for path update */}
      <MuiSnackbar
        message={pathSnackbarInfo.message}
        open={pathSnackbarInfo.open}
        handleClose={closeSnackbar}
        severity={pathSnackbarInfo.severity} // ["error", "info", "warning", "success"]
        position={pathSnackbarInfo.position} // ["top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"]
      />

      {/* snackbar for upload info */}
      <MuiSnackbar
        message={uploadSnackbarInfo.message}
        open={uploadSnackbarInfo.open}
        handleClose={closeUploadSnackbar}
        severity={uploadSnackbarInfo.severity} // ["error", "info", "warning", "success"]
        position={uploadSnackbarInfo.position} // ["top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"]
      />
      {/* snackbar for upload info */}
      <MuiSnackbar
        message={uploadRoadConditionSnackbarInfo.message}
        open={uploadRoadConditionSnackbarInfo.open}
        handleClose={closeUploadRoadConditionSnackbar}
        severity={uploadRoadConditionSnackbarInfo.severity} // ["error", "info", "warning", "success"]
        position={uploadRoadConditionSnackbarInfo.position} // ["top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"]
      />
    </>
  );
}
