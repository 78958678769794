import { useEffect, useReducer, useContext, useRef, useCallback } from "react";
import styles from "./UploadDataPoint.module.css";
import ImageView from "./imageView/ImageView";
import { Button, makeStyles } from "@material-ui/core";
import LabelSelectionView from "./labelSelectionView/LabelSelectionView";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import withLoader from "../../../HOC/withLoader";
import MapWithDraggableMarker from "../../maps/mapWithDraggableMarker/MapWithDraggableMarker";
import {
  createBoundingBoxesRequest,
  createWorkOrder,
  updateDataPointRequest,
  uploadImageRequest,
} from "../../../utils/api/uploadImageAPI";
import { AuthContext } from "../../AuthContext";

import { dataURItoBlob } from "utils/fileUtils/fileUtils";
import { getUUIDv4 } from "utils/ramdom/ramdom";

const TAG = "UploadDataPointView.js";
const useStyle = makeStyles(() => ({
  addLabelButton: {
    color: "#fff",
    "&:disabled": {
      color: "grey",
    },
  },
  actionButton: {
    "&:disabled": {
      color: "#fff",
      background: "#e3e3e3e3",
    },
  },
}));

const DEFAULT_LABEL_TYPE = "N / A";
const DEFAULT_LABEL_NAME = "N / A";

const defaultType = {
  ancesstorChain: [0],
  name: DEFAULT_LABEL_NAME,
  id: -1,
  rangeTitle: "",
  range: 0,
};

const initialState = {
  location: null,
  mapScreenshotFile: null,
  imageFile: null,
  labelTypes: [],
  labelObject: {},
  selectedLabelTypes: [],
  comment: "",
};

const ACTIONS = {
  SET_LOCATION: "SET LOCATION",
  SET_MAP_SCREEN_SHOT_FILE: "SET MAP SCREEN SHOT_FILE",
  SET_IMAGE_FILE: "SET IMAGE FILE",
  SET_LABEL_TYPES: "SET LABEL TYPES",
  SET_LABEL_OBJECT: "SET LABEL OBJECT",
  SET_SELECTED_LABEL_TYPES: "SET SELECTED LABEL TYPES",
  SET_COMMENT: "SET COMMENT",
  RESET: "RESET",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  console.log(`reducer`, type, payload, state);
  switch (type) {
    case ACTIONS.SET_LOCATION:
      return { ...state, location: payload };

    case ACTIONS.SET_MAP_SCREEN_SHOT_FILE:
      return { ...state, mapScreenshotFile: payload };

    case ACTIONS.SET_IMAGE_FILE:
      return { ...state, imageFile: payload };

    case ACTIONS.SET_LABEL_TYPES:
      return { ...state, labelTypes: payload };

    case ACTIONS.SET_LABEL_OBJECT:
      return { ...state, labelObject: payload };

    case ACTIONS.SET_SELECTED_LABEL_TYPES:
      return { ...state, selectedLabelTypes: payload };

    case ACTIONS.SET_COMMENT:
      return { ...state, comment: payload };

    case ACTIONS.RESET:
      return {
        ...initialState,
        location: state.location,
        labelTypes: state.labelTypes,
        labelObject: { ...state.labelObject },
      };

    default:
      return initialState;
  }
};

function UploadDataPointView(props) {
  const rRef = useRef();
  const classes = useStyle();
  const screenshotFileRef = useRef(null);

  const {
    contextToken: token,
    deviceInfo,
    labels,
    contextUsername,
  } = useContext(AuthContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { labelTypes, labelObject, selectedLabelTypes, location, imageFile } =
    state;

  const handleImageChange = (imageFile) => {
    dispatch({ type: ACTIONS.SET_IMAGE_FILE, payload: imageFile });
  };

  const addLabelType = () => {
    const updatedLabelTypes = selectedLabelTypes.concat(
      JSON.parse(JSON.stringify(defaultType))
    );
    dispatch({
      type: ACTIONS.SET_SELECTED_LABEL_TYPES,
      payload: updatedLabelTypes,
    });
  };

  const removeLabelType = (index) => {
    if (selectedLabelTypes.length < 2) return;
    const updatedLabelTypes = JSON.parse(JSON.stringify(selectedLabelTypes));
    updatedLabelTypes.splice(index, 1);
    dispatch({
      type: ACTIONS.SET_SELECTED_LABEL_TYPES,
      payload: updatedLabelTypes,
    });
  };

  /**
   *
   * @param {number} index index of the target label in selectedLabels array
   * @param {string} labelType type of the label
   * @param {{name: string, id: number}} labelInfo a brief label info
   * @returns
   */
  const updateSelectedLabel = (index, idChain, labelInfo = defaultType) => {
    if (index < 0 || index >= selectedLabelTypes.length) return;
    const updatedLabelTypes = [...selectedLabelTypes];

    updatedLabelTypes[index] = {
      ancesstorChain: idChain,
      name: labelInfo.name,
      id: labelInfo.id,
      range: labelInfo.range,
    };
    // debugger;
    dispatch({
      type: ACTIONS.SET_SELECTED_LABEL_TYPES,
      payload: updatedLabelTypes,
    });
  };

  /**
   * @description if one of the selected label's id is -1, which means
   * one label selection is not completed, should not allow user to add
   * another selection entry
   *
   * @returns whehter or not the add button should be disabled
   */
  const isAddBtnDisabled = () => {
    const selectedLabelIds = getSelectedLabelIds();
    return selectedLabelIds.indexOf(-1) >= 0;
    // return true
  };

  /**
   * @description when selected labels array is empty or one of the label's id is -1, action buttons
   * should be disabled
   * @returns whehter or not actions buttons should be disabled
   */
  const isActionBtnDisabled = () => {
    const selectedLabelIds = getSelectedLabelIds();
    // return selectedLabelIds.length === 0 || isAddBtnDisabled();
    return selectedLabelIds.length === 0 || isAddBtnDisabled() || !imageFile;
  };

  const getSelectedLabelIds = () => {
    return Object.values(selectedLabelTypes).reduce((acc, ele) => {
      acc.push(ele.id);
      return acc;
    }, []);
  };

  const handleSubmit = async () => {
    // await takeScreenShot();
    const { setShowLoader, onUploadSuccess, handleClose, onUploadFailed } =
      props;

    console.log(`state`, state);
    const { id: deviceId } = deviceInfo;
    const {
      location: { lat, lng },
      imageFile,
      comment = "",
    } = state;
    try {
      let dataPointId;
      setShowLoader(true);
      const {
        success,
        result: dataId,
        message,
      } = await uploadImageRequest(token, deviceId, lat, lng, imageFile);

      // remove local map view screenshot as it is now generated on lambda function
      // const screenshotFile = screenshotFileRef.current;
      // const newFileName =
      //   `vaughan_${dataId}_lat_${lat}_lng_${lng}_${screenshotFile.name}`.replace(
      //     /\./g,
      //     "_"
      //   );

      // const mapViewScreenshotImageName = await uploadMapviewScreenshot(
      //   screenshotFile,
      //   newFileName
      // );

      // if (!mapViewScreenshotImageName) alert("ERROR, uploda map view error");

      const imageUrl = message?.image || "";
      if (success && dataId) {
        dataPointId = dataId;
      }

      // console.log(`dataPointId`, dataPointId);
      if (dataPointId) {
        if (comment !== "") {
          const updateResult = await updateDataPointRequest(
            token,
            dataPointId,
            comment
          );
        }

        // create bounding boxes for each defect, with data point id
        const createBoundingBoxRequests = state.selectedLabelTypes.map(
          (labelType) =>
            createBoundingBoxesRequest(token, labelType, dataPointId)
        );
        const createdBondingBoxesResults = await Promise.all(
          createBoundingBoxRequests
        );

        const createWorkOrderRequests = [];

        for (const result of createdBondingBoxesResults) {
          // debugger;
          const boundingBoxId = result?.result?.label_id;
          const label_type = result?.label_type;
          const w_o_type = result?.w_o_type;

          const params = {
            lat: lat + "",
            lon: lng + "",
            datapoint_id: dataPointId + "",
            label_id: boundingBoxId + "",
            note: comment,
            userName: contextUsername
              .toLowerCase()
              .split("_")
              .map((c) => {
                const trimmed = c.trim();
                // console.log("trimmed", trimmed)
                const temp =
                  trimmed.charAt(0).toUpperCase() + trimmed.substring(1);
                // debugger
                // console.log("temp", temp)
                return temp;
              })
              .join(" "),
            imageUrl: imageUrl,
            w_o_type,
            label_type: label_type + "", // Pothole Paved Surface dts > 0
            open_datetime: new Date().toJSON(),
            assign_to: "",
            severity: "",
            w_o_id: "vaughan" + new Date().getFullYear() + "-" + boundingBoxId,
            map_image_url: "",
            inspected_on: "",
            inspected_by: "",
          };
          if (boundingBoxId) {
            createWorkOrderRequests.push(createWorkOrder(params));
          }
        }

        if (createWorkOrderRequests.length > 0) {
          console.log(TAG, "START creating bounding boxes: ");
          try {
            const results = await Promise.all(createWorkOrderRequests).catch(
              (e) => alert("Error in creating work order: " + e.message)
            );
            console.log(TAG, "END creating bounding boxes: ", results);
          } catch (error) {
            console.log(TAG, "Error in creating bounding boxes: ", error);
          }
        }
      }

      setShowLoader(false);
      onUploadSuccess();
      handleClose();
      // debugger;
    } catch (error) {
      // debugger;
      setShowLoader(false);
      onUploadFailed();
    } finally {
      // setShowLoader(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_LOCATION,
      payload: { lat: props.position.lat, lng: props.position.lng },
    });
  }, [props.position.lat, props.position.lng]);

  // IMPORTANT for some reason, when this view is rerendered, the
  // ancesstorChain is set to [] by default, will figure it out later
  // explict set it the porper default value over here
  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_SELECTED_LABEL_TYPES,
      payload: [JSON.parse(JSON.stringify(defaultType))],
    });
  }, []);

  const handleLocationUpdate = useCallback((newLocation) => {
    if (newLocation) {
      const { lat, lng } = newLocation;
      const refinedLat = Number(lat.toFixed(7));
      const refinedLng = Number(lng.toFixed(7));
      // console.log(`refinedLat, refinedLng`, refinedLat, refinedLng);
      dispatch({
        type: ACTIONS.SET_LOCATION,
        payload: { lat: refinedLat, lng: refinedLng },
      });
    }
  }, []);

  if (!location) {
    return (
      <div>
        <h2>Please enable location service to continue</h2>
      </div>
    );
  }

  const takeScreenShot = async () => {
    const screenshot = await rRef?.current.takeScreenshot();

    if (screenshot && screenshot.dataUrl) {
      const blob = dataURItoBlob(screenshot.dataUrl);
      const fileName = `uuidv4_${getUUIDv4().replace(/-/g, "_")}`;
      const resultFile = new File([blob], fileName, {
        type: blob.type,
      });
      screenshotFileRef.current = resultFile;
    }
  };

  return (
    <div className={styles["upload-data-point-wrapper"]}>
      <form
        className={styles["upload-data-point__form-wrapper"]}
        onSubmit={(e) => e.preventDefault()}
      >
        <div style={{ width: "100%", height: 400 }}>
          <MapWithDraggableMarker
            onLocationUpdate={handleLocationUpdate}
            center={props.position}
            ref={rRef}
          />
        </div>
        <>
          <div>
            <ImageView handleImageChange={handleImageChange} />
          </div>
          <div>
            {selectedLabelTypes.map(({ id, ancesstorChain }, index) => (
              <LabelSelectionView
                ancesstorChain={ancesstorChain}
                key={index}
                labels={labels}
                labelIndex={index}
                removeLabelType={removeLabelType}
                updateSelectedLabel={updateSelectedLabel}
              />
            ))}
          </div>

          <div>
            <Button
              onClick={addLabelType}
              className={classes.addLabelButton}
              // color="primary"
              // size="small"
              disabled={isAddBtnDisabled()}
              startIcon={<AddCircleIcon style={{ fontSize: 40 }} />}
            >
              Add Type
            </Button>
          </div>

          <hr />
          <div>
            <h3>Comment</h3>
            <textarea
              className={styles.textarea}
              onChange={(event) => {
                dispatch({
                  type: ACTIONS.SET_COMMENT,
                  payload: event.target.value,
                });
              }}
            />
          </div>
          {/* {!isActionBtnDisabled() && ( */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              className={classes.actionButton}
              style={{
                marginRight: 20,
                color: "#fff",
                borderColor: "#fff",
              }}
              // color="secondary"
              variant="outlined"
              disabled={isActionBtnDisabled()}
              onClick={() => dispatch({ type: ACTIONS.RESET, payload: null })}
            >
              Reset
            </Button>
            <Button
              className={classes.actionButton}
              color="primary"
              variant="contained"
              disabled={isActionBtnDisabled()}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {/* <Button onClick={handleUploadScreenshot}>upload screenshot</Button> */}
          </div>
          {/* )} */}
        </>
      </form>
    </div>
  );
}

export default withLoader(UploadDataPointView);
