// Set to Production Server
// const IRISGO_BASE_URL = new URL("https://www.demo.com");

// Set to Test Server
const IRISGO_BASE_URL = new URL("https://www.demo.com");

const IRISGO_LOGIN_URL = `${IRISGO_BASE_URL}token/`;
const IRISGO_LOOKUP_URL = `${IRISGO_BASE_URL}lookup/v1/`;
const IRISGO_DEVICE_UPDATE_URL = `${IRISGO_BASE_URL}device/`;
const IRISGO_USER_PROFILE_UPDATE_URL = `${IRISGO_BASE_URL}profile/`;
const IRISGO_FETCH_URL = `${IRISGO_BASE_URL}datapoint/`;
const IRISGO_DISTANCE_URL = `${IRISGO_BASE_URL}report/distance/`;
const IRISGO_LABELER_URL = `${IRISGO_BASE_URL}report/view/`;
const IRISGO_UPLOAD_URL = `${IRISGO_BASE_URL}geo_info/`;
const IRISGO_UPDATE_URL = `${IRISGO_BASE_URL}datapoint`;
const IRISGO_DEVICE_USAGE_URL = `${IRISGO_BASE_URL}report/device/`;
const IRISGO_NEW_DEVICE_URL = `${IRISGO_BASE_URL}device/new/`;
const IRISGO_DOWNLOAD_CSV_URL = `${IRISGO_BASE_URL}download_csv`;
const IRISGO_CREATE_NEW_USER_URL = `${IRISGO_BASE_URL}create_user/`;
const IRISGO_RESET_USER_PASSWORD = `${IRISGO_BASE_URL}change_password/`;
const IRISGO_LABEL_URL = `${IRISGO_BASE_URL}label/`;

// IrisAdmin API Gateway
const IRISGO_ADMIN_GATEWAY_URL = new URL("https://www.demo.com");
const IRISGO_ADMIN_GATEWAY_DEVICE_URL = `${IRISGO_ADMIN_GATEWAY_URL}device`;

// Sentry API Gateway
const SENTRY_GATEWAY_URL = new URL("https://www.demo.com");
const SENTRY_GATEWAY_LOOKUP_URL = `${SENTRY_GATEWAY_URL}lookup`;

// Hexnode API Base
const HEXNODE_URL = new URL("https://www.demo.com");
const HEXNODE_UPDATE_DEVICE_NAME_URL = `${HEXNODE_URL}actions/save_friendly_name/`;
const HEXNODE_LOOKUP_URL = `${HEXNODE_URL}devices/`;

// Open Weather API
const OPEN_WEATHER_URL = new URL("https://www.demo.com");
const OPEN_WEATHER_CURRENT_URL = `${OPEN_WEATHER_URL}weather`;

// GIS Map

const GIS_MAP_URL = new URL("https://www.demo.com");

// Device status
const DEVICE_STATUS_URL = "";
const REBOOT_DEVICE_URL = ``;

export const REQUEST_NEW_ACCOUNT_URL = "https://www.demo.com";

// create work order entry url
const CREATE_WORK_ORDER_URL = "https://www.demo.com";

// const CREATE_WORK_ORDER_URL =
//   "https://www.demo.com";

export {
  GIS_MAP_URL,
  IRISGO_BASE_URL,
  IRISGO_DEVICE_UPDATE_URL,
  IRISGO_USER_PROFILE_UPDATE_URL,
  IRISGO_LOGIN_URL,
  IRISGO_LOOKUP_URL,
  IRISGO_FETCH_URL,
  IRISGO_DISTANCE_URL,
  IRISGO_LABELER_URL,
  IRISGO_UPLOAD_URL,
  IRISGO_UPDATE_URL,
  IRISGO_DEVICE_USAGE_URL,
  IRISGO_ADMIN_GATEWAY_DEVICE_URL,
  SENTRY_GATEWAY_LOOKUP_URL,
  HEXNODE_UPDATE_DEVICE_NAME_URL,
  HEXNODE_LOOKUP_URL,
  IRISGO_NEW_DEVICE_URL,
  OPEN_WEATHER_CURRENT_URL,
  IRISGO_DOWNLOAD_CSV_URL,
  IRISGO_CREATE_NEW_USER_URL,
  IRISGO_RESET_USER_PASSWORD,
  IRISGO_LABEL_URL,
  CREATE_WORK_ORDER_URL,
  DEVICE_STATUS_URL,
  REBOOT_DEVICE_URL,
};
